import { default as axios } from "axios";
import {
  ACTIVATE_USER_API_URL,
  LOGIN_API_URL,
  SIGNUP_API_URL,
  USER_INFO_API_URL,
  USERS_API_URL,
} from "../api.routes";
import { getAuthToken } from "../auth/AuthContext";

export async function auth_signup(data) {
  const res = await axios.post(SIGNUP_API_URL, data, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
}

export async function auth_login(data) {
  const params = new URLSearchParams();
  params.append("grant_type", "password");
  params.append("username", data.username);
  params.append("password", data.password);

  const res = await axios.post(LOGIN_API_URL, params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  return res;
}

export async function activate_account(activate_token) {
  const res = await axios.get(ACTIVATE_USER_API_URL, {
    params: {
      token: activate_token,
    },
  });
  return res;
}

export async function get_users() {
  const res = await axios.get(USERS_API_URL, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
}

export async function delete_user(userId) {
  const res = await axios.delete(`${USERS_API_URL}/${userId}`, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
}

export async function get_user_info() {
  const res = await axios.get(USER_INFO_API_URL, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
}
