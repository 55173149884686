import { default as axios } from "axios";
import { POSITIONS_API_URL } from "../api.routes";
import { getAuthToken } from "../auth/AuthContext";

export async function closePositionWithTradeId(tradeId) {
  const res = await axios.delete(POSITIONS_API_URL + "/" + tradeId, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
}
