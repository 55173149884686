import React, { useEffect, useState } from "react";
import {
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import CustomCard from "./CustomCard";
import CustomLoader from "./CustomLoader";
import CustomSwitch from "./CustomSwitch";
import CustomTab from "./CustomTab";
import CustomTooltip from "./CustomTooltip";
import { CustomToast } from "./CustomToast";

import { closePositionWithTradeId } from "../services/positions.service";
import { attachSLOrder, trailSLOrder } from "../services/stop_loss.service";
import { attachTPOrder } from "../services/take_profit.service";

import { numbersOnly } from "../validator/numberValidator";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";

import { useAuth } from "../auth/AuthContext";

const PositionsTableSection = ({ isPositionsLoading, positions }) => {
  const { logout } = useAuth();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openTakeProfitModal, setOpenTakeProfitModal] = useState(false);
  const [openStopLossModal, setOpenStopLossModal] = useState(false);
  const [openTrailStopLossModal, setOpenTrailStopLossModal] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const takeProfitOrderTypeOptions = [
    { label: "Limit Order", value: "limit" },
    { label: "Market Order", value: "market" },
  ];
  const [takeProfitOrderType, setTakeProfitOrderType] = useState("limit");
  const [takeProfitAmount, setTakeProfitAmount] = useState(null);
  const [takeProfitAmountUSD, setTakeProfitAmountUSD] = useState(null); // For now same as takeProfitAmount
  const [takeProfitPercentage, setTakeProfitPercentage] = useState(null);

  const stopLossOrderTypeOptions = [
    { label: "Cond. Limit Order", value: "limit" },
    { label: "Cond. Market Order", value: "market" },
  ];
  const [stopLossOrderType, setStopLossOrderType] = useState("limit");
  const stopLossPriceOptions = ["Last", "Open"];
  const [stopLossAmount, setStopLossAmount] = useState(null);
  const [stopLossAmountUSD, setStopLossAmountUSD] = useState(null); // For now same as stopLossAmount
  const [stopLossPercentage, setStopLossPercentage] = useState(null);
  const [stopLossLimitPercentage, setStopLossLimitPercentage] = useState(null);

  const takeProfitSectionValues = {
    take_profit_order_type: null,
    is_trailing_take_profit_for_last_target: false,
    take_profit_max_price_with_deviation_percentage: 0,
    take_profit: [],
  };

  const stopLossSectionValues = {
    stop_loss_order_type: null,
    stop_loss_price_type: stopLossPriceOptions[0].toLowerCase(),
    stop_loss_price: null,
    stop_loss_limit_price: null,
    is_stop_loss_timeout: false,
    stop_loss_timeout: null,
    is_trailing_stop_loss: false,
    trailing_stop_loss_percentage: null,
    is_move_to_breakeven: false,
    move_to_breakeven_points: null,
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    setFocus,
    reset,
    getFieldState,
    formState: { errors },
  } = useForm({
    defaultValues: {
      trade_id: null,
      ...stopLossSectionValues,
      ...takeProfitSectionValues,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "take_profit",
  });

  let takeProfits = watch("take_profit");
  let isTrailingTakeProfitForLastTarget = watch(
    "is_trailing_take_profit_for_last_target"
  );

  let isStopLossTimeout = watch("is_stop_loss_timeout");
  let stopLossPrice = watch("stop_loss_price");
  let stopLossLimitPrice = watch("stop_loss_limit_price");
  let isTrailingStopLoss = watch("is_trailing_stop_loss");
  let isMoveToBreakeven = watch("is_move_to_breakeven");

  const handleTakeProfitOrderTypeChange = (selectedIndex) => {
    setTakeProfitOrderType(selectedIndex);
  };

  const handleStopLossOrderTypeChange = (selectedIndex) => {
    setStopLossOrderType(selectedIndex);
  };

  const onSubmit = (data) => {
    setFormSubmitted(true);
    const filteredFormData = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => value !== null && value !== "" && value !== undefined
      )
    );
    data = filteredFormData;

    if (openTakeProfitModal) {
      data["take_profit_order_type"] = takeProfitOrderType;

      if (data.take_profit.length > 0) {
        let error_occurred = false;
        let total_tp_quantity = 0;
        data.take_profit.forEach((item, index) => {
          const { price, quantity } = item;
          if (price === null || price === "" || price === undefined) {
            setError(`take_profit.${index}.price`, {
              type: "required",
              message: "Price is required",
            });
            setFocus(`take_profit.${index}.price`);
            error_occurred = true;
            setFormSubmitted(false);
            return;
          }
          if (price <= 0) {
            setError(`take_profit.${index}.price`, {
              type: "min",
              message: "Price must be > 0",
            });
            setFocus(`take_profit.${index}.price`);
            error_occurred = true;
            setFormSubmitted(false);
            return;
          }
          item.price = parseFloat(price);
          if (quantity === null || quantity === "" || quantity === undefined) {
            setError(`take_profit.${index}.quantity`, {
              type: "required",
              message: "Quantity is required",
            });
            setFocus(`take_profit.${index}.quantity`);
            error_occurred = true;
            setFormSubmitted(false);
            return;
          }
          if (quantity <= 0) {
            setError(`take_profit.${index}.quantity`, {
              type: "min",
              message: "Quantity must be > 0",
            });
            setFocus(`take_profit.${index}.quantity`);
            error_occurred = true;
            setFormSubmitted(false);
            return;
          }
          item.quantity = parseFloat(quantity);
          total_tp_quantity = total_tp_quantity + parseFloat(quantity);
        });
        if (total_tp_quantity !== parseFloat(100)) {
          setError(`take_profit_add`, {
            type: "min",
            message: "Take Profit total quantity must be equal to 100%",
          });
          error_occurred = true;
        }
        if (error_occurred) {
          setFormSubmitted(false);
          return;
        }
      } else {
        setError("take_profit_add", {
          type: "min",
          message: "At least 1 Take Profit entry should be added",
        });
        setFocus("take_profit_add");
        setFormSubmitted(false);
        return;
      }

      if (data["take_profit_order_type"] === "limit") {
        if (data.is_trailing_take_profit_for_last_target) {
          if (
            isNaN(
              parseFloat(data.take_profit_max_price_with_deviation_percentage)
            ) ||
            parseFloat(data.take_profit_max_price_with_deviation_percentage) >=
              0
          ) {
            setError("take_profit_max_price_with_deviation_percentage", {
              type: "max",
              message: "Follow max price with deviation (%) must be < 0",
            });
            setFocus("take_profit_max_price_with_deviation_percentage");
            setFormSubmitted(false);
            return;
          } else {
            data.take_profit_max_price_with_deviation_percentage = parseFloat(
              data.take_profit_max_price_with_deviation_percentage
            );
          }
        } else {
          if ("take_profit_max_price_with_deviation_percentage" in data) {
            delete data["take_profit_max_price_with_deviation_percentage"];
          }
        }
      } else {
        if ("is_trailing_take_profit_for_last_target" in data) {
          delete data["is_trailing_take_profit_for_last_target"];
        }
        if ("take_profit_max_price_with_deviation_percentage" in data) {
          delete data["take_profit_max_price_with_deviation_percentage"];
        }
      }

      // TODO: Later decide what to do with market take_profit or stop_loss orders
      if (data["take_profit_order_type"] === "market") {
        data["take_profit_order_type"] = "limit";
      }

      Object.keys(stopLossSectionValues).forEach((key) => {
        if (key in data) {
          delete data[key];
        }
      });

      attachTPOrder(data)
        .then((response) => {
          if (response.data.success) {
            CustomToast({
              message: response.data.msg,
              type: "success",
            });
            setOpenTakeProfitModal(false);
          } else {
            CustomToast({
              message: response.data.msg,
              type: "error",
            });
          }
          setFormSubmitted(false);
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            CustomToast({
              message: response.data.detail,
              type: "error",
            });
            logout();
          } else {
            CustomToast({
              message: response.data,
              type: "error",
            });
          }
          setFormSubmitted(false);
        });
    } else if (openStopLossModal) {
      data["stop_loss_order_type"] = stopLossOrderType;

      if (data.stop_loss_price) {
        if (parseFloat(data.stop_loss_price) <= 0) {
          setError("stop_loss_price", {
            type: "min",
            message: "Stop loss price must be > 0",
          });
          setFocus("take_profit_max_price_with_deviation_percentage");
          setFormSubmitted(false);
          return;
        } else {
          data.stop_loss_price = parseFloat(data.stop_loss_price);
        }
      } else {
        setError("stop_loss_price", {
          type: "required",
          message: "This input is required.",
        });
        setFocus("stop_loss_price");
        setFormSubmitted(false);
        return;
      }

      if (data["stop_loss_order_type"] === "limit") {
        if (data.stop_loss_limit_price) {
          if (parseFloat(data.stop_loss_limit_price) <= 0) {
            setError("stop_loss_limit_price", {
              type: "min",
              message: "Order Price must be > 0",
            });
            setFocus("stop_loss_limit_price");
            setFormSubmitted(false);
            return;
          } else {
            data.stop_loss_limit_price = parseFloat(data.stop_loss_limit_price);
          }
        } else {
          setError("stop_loss_limit_price", {
            type: "required",
            message: "This input is required.",
          });
          setFocus("stop_loss_limit_price");
          setFormSubmitted(false);
          return;
        }
      } else {
        if ("stop_loss_limit_price" in data) {
          delete data["stop_loss_limit_price"];
        }
      }

      if (data.is_stop_loss_timeout) {
        if (data.stop_loss_timeout) {
          if (parseInt(data.stop_loss_timeout) <= 0) {
            setError("stop_loss_timeout", {
              type: "min",
              message: "Stop Loss timeout must be > 0",
            });
            setFocus("stop_loss_timeout");
            setFormSubmitted(false);
            return;
          } else {
            data.stop_loss_timeout = parseInt(data.stop_loss_timeout);
          }
        } else {
          setError("stop_loss_timeout", {
            type: "required",
            message: "This input is required.",
          });
          setFocus("stop_loss_timeout");
          setFormSubmitted(false);
          return;
        }

        if (data.is_trailing_stop_loss) {
          if (data.trailing_stop_loss_percentage) {
            if (parseFloat(data.trailing_stop_loss_percentage) <= 0) {
              setError("trailing_stop_loss_percentage", {
                type: "min",
                message: "Trailing stop loss(%) must be > 0",
              });
              setFocus("trailing_stop_loss_percentage");
              setFormSubmitted(false);
              return;
            } else {
              data.trailing_stop_loss_percentage = parseFloat(
                data.trailing_stop_loss_percentage
              );
            }
          } else {
            setError("trailing_stop_loss_percentage", {
              type: "required",
              message: "This input is required.",
            });
            setFocus("trailing_stop_loss_percentage");
            setFormSubmitted(false);
            return;
          }
        } else {
          if ("trailing_stop_loss_percentage" in data) {
            delete data["trailing_stop_loss_percentage"];
          }
        }

        if (data.is_move_to_breakeven) {
          if (data.move_to_breakeven_points) {
            if (parseFloat(data.move_to_breakeven_points) <= 0) {
              setError("move_to_breakeven_points", {
                type: "min",
                message: "Move to breakeven points must be > 0",
              });
              setFocus("move_to_breakeven_points");
              setFormSubmitted(false);
              return;
            } else {
              data.move_to_breakeven_points = parseFloat(
                data.move_to_breakeven_points
              );
            }
          } else {
            setError("move_to_breakeven_points", {
              type: "required",
              message: "This input is required.",
            });
            setFocus("move_to_breakeven_points");
            setFormSubmitted(false);
            return;
          }
        } else {
          if ("move_to_breakeven_points" in data) {
            delete data["move_to_breakeven_points"];
          }
        }
      } else {
        if ("stop_loss_timeout" in data) {
          delete data["stop_loss_timeout"];
        }
      }

      // TODO: For now making limit sl even on selecting market order because not supported by ccxt coinbasepro
      if (data["stop_loss_order_type"] === "market") {
        data["stop_loss_order_type"] = "limit";
        data["stop_loss_limit_price"] = data["stop_loss_price"];
      }

      Object.keys(takeProfitSectionValues).forEach((key) => {
        if (key in data) {
          delete data[key];
        }
      });

      attachSLOrder(data)
        .then((response) => {
          if (response.data.success) {
            CustomToast({
              message: response.data.msg,
              type: "success",
            });
            setOpenStopLossModal(false);
          } else {
            CustomToast({
              message: response.data.msg,
              type: "error",
            });
          }
          setFormSubmitted(false);
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            CustomToast({
              message: response.data.detail,
              type: "error",
            });
            logout();
          } else {
            CustomToast({
              message: response.data,
              type: "error",
            });
          }
          setFormSubmitted(false);
        });
    } else if (openTrailStopLossModal) {
      data = {
        trade_id: data.trade_id,
        trailing_stop_loss_percentage: data.trailing_stop_loss_percentage,
      };

      if (data.trailing_stop_loss_percentage) {
        if (parseFloat(data.trailing_stop_loss_percentage) <= 0) {
          setError("trailing_stop_loss_percentage", {
            type: "min",
            message: "Trailing stop loss(%) must be > 0",
          });
          setFocus("trailing_stop_loss_percentage");
          setFormSubmitted(false);
          return;
        } else {
          data.trailing_stop_loss_percentage = parseFloat(
            data.trailing_stop_loss_percentage
          );
        }
      } else {
        setError("trailing_stop_loss_percentage", {
          type: "required",
          message: "This input is required.",
        });
        setFocus("trailing_stop_loss_percentage");
        setFormSubmitted(false);
        return;
      }

      trailSLOrder(data)
        .then((response) => {
          if (response.data.success) {
            CustomToast({
              message: response.data.msg,
              type: "success",
            });
            setOpenTrailStopLossModal(false);
          } else {
            CustomToast({
              message: response.data.msg,
              type: "error",
            });
          }
          setFormSubmitted(false);
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            CustomToast({
              message: response.data.detail,
              type: "error",
            });
            logout();
          } else {
            CustomToast({
              message: response.data,
              type: "error",
            });
          }
          setFormSubmitted(false);
        });
    }
  };

  const exitPosition = (tradeId) => {
    closePositionWithTradeId(tradeId)
      .then((response) => {
        if (response.data.success) {
          CustomToast({
            message: response.data.msg,
            type: "success",
          });
        } else {
          CustomToast({
            message: response.data.msg,
            type: "error",
          });
        }
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          CustomToast({
            message: response.data.detail,
            type: "error",
          });
          logout();
        } else {
          CustomToast({
            message: response.data,
            type: "error",
          });
        }
      });
  };

  useEffect(() => {
    if (!selectedPosition || !takeProfits) {
      return;
    }

    let totalProfit = 0.0;
    let totalProfitUSD = null; // For now same as totalProfit as working on USD based coins
    let totalProfitPercentage = null;
    let totalInvestment = null;
    let buyPrice = null;
    let entryOrderQty = selectedPosition.entry_order_quantity;

    if (selectedPosition.use_existing_assets) {
      entryOrderQty =
        selectedPosition.assets_amount_to_use / selectedPosition.ltp;
    }

    if (parseFloat(entryOrderQty) <= 0) {
      setTakeProfitAmount(null);
      setTakeProfitAmountUSD(null);
      setTakeProfitPercentage(null);
      return;
    }

    if (selectedPosition.entry_order_filled_price !== null) {
      buyPrice = selectedPosition.entry_order_filled_price;
    } else {
      if (
        selectedPosition.entry_order_type !== "market" &&
        !selectedPosition.use_existing_assets
      ) {
        if (selectedPosition.entry_order_type.includes("limit")) {
          // "limit" or "cond. limit" type
          buyPrice = selectedPosition.entry_order_price;
        } else {
          // "cond. market" type
          buyPrice = selectedPosition.entry_order_trigger_price;
        }
      } else {
        buyPrice = selectedPosition.ltp;
      }
    }

    if (buyPrice && parseFloat(buyPrice) > 0) {
      totalInvestment = entryOrderQty * buyPrice;
    } else {
      setTakeProfitAmount(null);
      setTakeProfitAmountUSD(null);
      setTakeProfitPercentage(null);
      return;
    }

    takeProfits.forEach((item) => {
      if (parseFloat(item.price) > 0 && parseFloat(item.quantity) > 0) {
        const profit =
          (parseFloat(item.price) - buyPrice) *
          (entryOrderQty * (parseFloat(item.quantity) / 100));
        totalProfit += profit;
      } else {
        setTakeProfitAmount(null);
        setTakeProfitAmountUSD(null);
        setTakeProfitPercentage(null);
        return;
      }
    });
    totalProfitUSD = totalProfit
      .toFixed(5)
      .toString()
      .replace(/\.?0*$/, "");
    totalProfitPercentage = (totalProfit / totalInvestment) * 100;
    totalProfitPercentage = totalProfitPercentage
      .toFixed(5)
      .toString()
      .replace(/\.?0*$/, "");

    if (totalProfit > 0) {
      totalProfit = "+" + totalProfit.toString();
    }
    if (totalProfitUSD > 0) {
      totalProfitUSD = "+" + totalProfitUSD.toString();
    }
    if (totalProfitPercentage > 0) {
      totalProfitPercentage = "+" + totalProfitPercentage.toString();
    }

    setTakeProfitAmount(totalProfit);
    setTakeProfitAmountUSD(totalProfitUSD + "$");
    setTakeProfitPercentage(totalProfitPercentage + "%");
  }, [
    selectedPosition,
    takeProfitOrderType,
    takeProfits,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getFieldState("take_profit"),
  ]);

  useEffect(() => {
    if (!selectedPosition) {
      return;
    }

    let slAmount = 0.0;
    let slAmountUSD = null; // For now same as slAmount as working on USD based coins
    let slPercentage = null;
    let slLimitPercentage = null;
    let buyPrice = selectedPosition.entry_order_filled_price;
    let exitPrice = null;
    let entryOrderQty = selectedPosition.entry_order_quantity;

    if (parseFloat(entryOrderQty) <= 0 || parseFloat(buyPrice) <= 0) {
      setStopLossPercentage(null);
      setStopLossLimitPercentage(null);
      setStopLossAmount(null);
      setStopLossAmountUSD(null);
      return;
    }

    if (parseFloat(stopLossPrice) > 0) {
      slPercentage = ((stopLossPrice - buyPrice) / buyPrice) * 100;
      slPercentage = slPercentage.toFixed(2) + "%";
      setStopLossPercentage(slPercentage);
    } else {
      setStopLossPercentage(null);
    }

    if (stopLossOrderType === "limit" && parseFloat(stopLossLimitPrice) > 0) {
      slLimitPercentage = ((stopLossLimitPrice - buyPrice) / buyPrice) * 100;
      slLimitPercentage = slLimitPercentage.toFixed(2) + "%";
      setStopLossLimitPercentage(slLimitPercentage);
    } else {
      setStopLossLimitPercentage(null);
    }

    if (stopLossOrderType === "limit") {
      exitPrice = stopLossLimitPrice;
    } else {
      exitPrice = stopLossPrice;
    }

    if (exitPrice && parseFloat(exitPrice) > 0) {
      slAmount = (exitPrice - buyPrice) * entryOrderQty;
      slAmountUSD =
        slAmount
          .toFixed(5)
          .toString()
          .replace(/\.?0*$/, "") + "$"; // For now same as slAmount as working on USD based coins
      setStopLossAmount(slAmount);
      setStopLossAmountUSD(slAmountUSD);
    } else {
      setStopLossAmount(null);
      setStopLossAmountUSD(null);
    }
  }, [selectedPosition, stopLossOrderType, stopLossPrice, stopLossLimitPrice]);

  return (
    <CustomCard cardTitle="Positions Table">
      {/* Beginning of Position Table  */}
      <TableContainer style={{ maxHeight: "60vh" }}>
        <Table>
          <TableHead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              background: "#fff",
            }}
          >
            <TableRow>
              <TableCell sx={{ fontWeight: 600 }}>Symbol</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Entry Time</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Entry Price</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Qty</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Current Price</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>PNL</TableCell>
              <TableCell sx={{ width: "25%", fontWeight: 600 }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ overflowY: "auto" }}>
            {isPositionsLoading ? (
              <TableRow>
                <TableCell style={{ textAlign: "center" }} colSpan={7}>
                  <CustomLoader />
                </TableCell>
              </TableRow>
            ) : positions.length > 0 ? (
              positions
                .sort(
                  (a, b) =>
                    new Date(b.entry_order_time) - new Date(a.entry_order_time)
                )
                .map((position) => (
                  <TableRow key={position.trade_id}>
                    <TableCell>{position.symbol}</TableCell>
                    <TableCell>{position.entry_order_time}</TableCell>
                    <TableCell>{position.entry_order_filled_price}</TableCell>
                    <TableCell>{position.entry_order_quantity}</TableCell>
                    <TableCell>{position.ltp}</TableCell>
                    <TableCell>
                      {position.pnl !== null ? position.pnl.toFixed(3) : null}
                    </TableCell>
                    <TableCell>
                      <Stack direction={"row"} gap={"10px"}>
                        {position.entry_order_status === "FILLED" && (
                          <>
                            {["CANCELED", null].includes(
                              position.tp_exit_order_status
                            ) && (
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                  fontWeight: 600,
                                }}
                                onClick={() => {
                                  reset();
                                  setSelectedPosition(position);
                                  setValue("trade_id", position.trade_id);
                                  // take_profit exist meaning qty are not in percentage
                                  // convert to percentage before display
                                  if (position.take_profit !== null) {
                                    var converted_take_profit = JSON.parse(
                                      position.take_profit
                                    );
                                    converted_take_profit =
                                      converted_take_profit.map((item) => {
                                        return {
                                          ...item,
                                          quantity:
                                            (item.quantity /
                                              position.entry_order_quantity) *
                                            100,
                                        };
                                      });
                                    setValue(
                                      "take_profit",
                                      converted_take_profit
                                    );
                                  } else {
                                    setValue("take_profit", []);
                                  }
                                  append([]);
                                  setOpenTakeProfitModal(true);
                                }}
                              >
                                {!position.is_take_profit ? "Attach" : "Edit"}{" "}
                                TP
                              </Button>
                            )}
                            {["CANCELED", null].includes(
                              position.sl_exit_order_status
                            ) && (
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                  fontWeight: 600,
                                }}
                                onClick={() => {
                                  setSelectedPosition(position);
                                  if (!position.is_stop_loss) {
                                    reset();
                                  } else {
                                    for (const key in stopLossSectionValues) {
                                      setValue(key, position[key]);
                                    }
                                  }
                                  setValue("is_stop_loss_timeout", false);
                                  setValue("is_trailing_stop_loss", false);
                                  setValue("is_move_to_breakeven", false);
                                  setValue("trade_id", position.trade_id);
                                  setOpenStopLossModal(true);
                                }}
                              >
                                {!position.is_stop_loss ? "Attach" : "Edit"} SL
                              </Button>
                            )}
                            {["OPEN"].includes(
                              position.sl_exit_order_status
                            ) && (
                              <Button
                                variant="contained"
                                color="success"
                                sx={{
                                  fontWeight: 600,
                                }}
                                onClick={() => {
                                  setValue("trade_id", position.trade_id);
                                  setSelectedPosition(position);
                                  setOpenTrailStopLossModal(true);
                                }}
                              >
                                Trail SL
                              </Button>
                            )}
                          </>
                        )}
                        {["OPEN", null].includes(position.position_status) && (
                          <Button
                            variant="contained"
                            color="error"
                            sx={{
                              fontWeight: 600,
                            }}
                            onClick={() => exitPosition(position.trade_id)}
                          >
                            {position.entry_order_status === "FILLED"
                              ? "Exit"
                              : "Cancel"}
                          </Button>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center", letterSpacing: "1.5px" }}
                  colSpan={7}
                >
                  No Open Positions found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* End of Position Table */}

      {/* Beginning of Take Profit Modal */}
      {selectedPosition && openTakeProfitModal && takeProfits && (
        <Modal
          open={openTakeProfitModal}
          onClose={() => {
            reset();
            setSelectedPosition(null);
            setOpenTakeProfitModal(false);
          }}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
              }
            }}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "background.paper",
              boxShadow: 24,
            }}
          >
            <CustomCard cardTitle="Take Profit" rightComponent={<></>}>
              <Stack direction="column" gap={"20px"}>
                <Stack gap={"10px"}>
                  <CustomTab
                    options={takeProfitOrderTypeOptions}
                    selectedOrderType={takeProfitOrderType}
                    onSelect={handleTakeProfitOrderTypeChange}
                  />
                  <span
                    style={{
                      color: "#5d6e77",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    The order will be placed on the exchange order book
                    beforehand
                  </span>
                </Stack>
                <Stack direction="row" gap="10px">
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Entry Filled Price:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "500" }}
                  >
                    {selectedPosition.entry_order_filled_price}
                  </Typography>
                </Stack>
                <Stack paddingBlock={"20px"} gap={"5px"}>
                  {fields.length > 0 && (
                    <Stack gap={"5px"}>
                      <Stack
                        paddingBottom={"10px"}
                        direction={"row"}
                        gap={"5px"}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            flex: 1,
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Price
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{
                            flex: 1,
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Quantity (%)
                        </Typography>
                        <span style={{ width: "40px" }}></span>
                      </Stack>
                      {fields.map((row, index) => (
                        <Stack
                          key={row.id}
                          direction={"row"}
                          gap={"5px"}
                          height={"50px"}
                        >
                          <Stack
                            direction={"column"}
                            textAlign={"center"}
                            flex={1}
                          >
                            <TextField
                              type="text"
                              size="small"
                              variant="standard"
                              placeholder="Price"
                              disabled={
                                [0, null].includes(
                                  selectedPosition.next_tp_data_index
                                )
                                  ? false
                                  : index < selectedPosition.next_tp_data_index
                                  ? true
                                  : false
                              }
                              onKeyDown={(e) => numbersOnly(e, "float", false)}
                              inputProps={{
                                style: { textAlign: "center" },
                              }}
                              style={{ width: "100%" }}
                              {...register(`take_profit.${index}.price`)}
                            />
                            {errors.take_profit &&
                              errors.take_profit[index]?.price?.message && (
                                <span
                                  style={{
                                    color: "#d32f2f",
                                    fontSize: 14,
                                    fontWeight: 500,
                                    marginTop: "5px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {errors.take_profit[index]?.price?.message}
                                </span>
                              )}
                          </Stack>
                          <Stack
                            direction={"column"}
                            textAlign={"center"}
                            flex={1}
                          >
                            <TextField
                              type="text"
                              size="small"
                              variant="standard"
                              placeholder="Quantity"
                              disabled={
                                [0, null].includes(
                                  selectedPosition.next_tp_data_index
                                )
                                  ? false
                                  : index < selectedPosition.next_tp_data_index
                                  ? true
                                  : false
                              }
                              onKeyDown={(e) => {
                                clearErrors("take_profit_add");
                                return numbersOnly(e, "float", false);
                              }}
                              inputProps={{
                                style: { textAlign: "center" },
                              }}
                              style={{ width: "100%" }}
                              {...register(`take_profit.${index}.quantity`)}
                            />
                            {errors.take_profit &&
                              errors.take_profit[index]?.quantity?.message && (
                                <span
                                  style={{
                                    color: "#d32f2f",
                                    fontSize: 14,
                                    fontWeight: 500,
                                    marginTop: "5px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {errors.take_profit[index]?.quantity?.message}
                                </span>
                              )}
                          </Stack>
                          <IconButton
                            disabled={
                              [0, null].includes(
                                selectedPosition.next_tp_data_index
                              )
                                ? false
                                : index < selectedPosition.next_tp_data_index
                                ? true
                                : false
                            }
                            aria-label="delete"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Stack>
                      ))}
                    </Stack>
                  )}
                  {fields.length < 5 ? (
                    <Stack style={{ textAlign: "center" }}>
                      <Button
                        name="take_profit_add"
                        sx={{
                          width: "fit-content",
                          marginInline: "auto",
                          fontWeight: 600,
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => {
                          let totalTPQuantity = 0.0;
                          takeProfits.forEach((field) => {
                            totalTPQuantity += parseFloat(field.quantity);
                          });
                          const remainingQuantity = 100 - totalTPQuantity;
                          append({
                            price: "",
                            quantity: remainingQuantity,
                          });
                        }}
                      >
                        Add
                      </Button>
                      {errors["take_profit_add"]?.message && (
                        <span
                          style={{
                            color: "#d32f2f",
                            fontSize: 14,
                            fontWeight: 500,
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          {errors["take_profit_add"]?.message}
                        </span>
                      )}
                    </Stack>
                  ) : null}
                  <Stack textAlign={"center"}>
                    <Typography>{fields.length} TP targets set</Typography>
                  </Stack>
                </Stack>
                {takeProfitOrderType === "limit" && (
                  <>
                    <Stack direction="row" alignItems="center">
                      <Stack direction="row" gap={"5px"}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ fontWeight: "600" }}
                        >
                          Trailing Take Profit for last target
                        </Typography>
                        <CustomTooltip text="Trailing Take Profit for last target tooltip" />
                      </Stack>
                      <Controller
                        name="is_trailing_take_profit_for_last_target"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            labelPlacement="start"
                            control={<CustomSwitch {...field} />}
                            style={{
                              paddingRight: "10px",
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ fontWeight: "600" }}
                      >
                        Follow max price with deviation (%)
                      </Typography>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        gap={"20px"}
                      >
                        <Controller
                          name="take_profit_max_price_with_deviation_percentage"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <>
                              <Slider
                                value={value === "" ? 0 : parseFloat(value)}
                                onChange={(e, val) => onChange(val)}
                                disabled={
                                  !isTrailingTakeProfitForLastTarget
                                    ? true
                                    : false
                                }
                                min={-100.0}
                                max={0}
                                step={0.01}
                                aria-labelledby="deviation-slider"
                                valueLabelDisplay="auto"
                                style={{
                                  marginLeft: "10px",
                                  cursor: isTrailingTakeProfitForLastTarget
                                    ? "default"
                                    : "not-allowed",
                                }}
                              />
                              <TextField
                                type="number"
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                                disabled={
                                  !isTrailingTakeProfitForLastTarget
                                    ? true
                                    : false
                                }
                                size="small"
                                onKeyDown={(e) => numbersOnly(e, "float", true)}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                  style: { fontSize: 15 },
                                }}
                                style={{
                                  width: "45%",
                                  cursor: isTrailingTakeProfitForLastTarget
                                    ? "default"
                                    : "not-allowed",
                                }}
                              />
                            </>
                          )}
                        />
                      </Stack>
                      {errors.take_profit_max_price_with_deviation_percentage
                        ?.message && (
                        <span
                          style={{
                            color: "#d32f2f",
                            fontSize: 14,
                            fontWeight: 500,
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          {
                            errors
                              .take_profit_max_price_with_deviation_percentage
                              ?.message
                          }
                        </span>
                      )}
                    </Stack>
                  </>
                )}
                <Stack direction="column" gap={"5px"}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Approximate Profit:
                  </Typography>
                  <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                    <img width="20px" height="20px" src="usdc.svg" alt="usdc" />
                    {takeProfitAmount &&
                    takeProfitAmountUSD &&
                    takeProfitPercentage ? (
                      <>
                        <span style={{ fontWeight: "600", color: "#00ae9a" }}>
                          {takeProfitAmount}
                        </span>
                        <span style={{ fontWeight: "600", color: "#00ae9a" }}>
                          {takeProfitAmountUSD}
                        </span>
                        <span style={{ fontWeight: "600", color: "#00ae9a" }}>
                          {takeProfitPercentage}
                        </span>
                      </>
                    ) : (
                      <span style={{ fontWeight: "600", color: "#00ae9a" }}>
                        ... ... ...
                      </span>
                    )}
                  </Stack>
                </Stack>
                <Stack direction="row" gap={"5px"}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={formSubmitted}
                    style={{
                      cursor: formSubmitted ? "not-allowed" : "default",
                    }}
                    sx={{
                      fontWeight: 600,
                      flex: 1,
                    }}
                  >
                    {!selectedPosition.is_take_profit ? "Attach" : "Edit"} TP
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{
                      fontWeight: 600,
                      flex: 1,
                    }}
                    onClick={() => {
                      reset();
                      setOpenTakeProfitModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </CustomCard>
          </form>
        </Modal>
      )}
      {/* End of Take Profit Modal */}

      {/* Beginning of Stop Loss Modal */}
      {selectedPosition && openStopLossModal && (
        <Modal
          open={openStopLossModal}
          onClose={() => {
            reset();
            setSelectedPosition(null);
            setOpenStopLossModal(false);
          }}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
              }
            }}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "background.paper",
              boxShadow: 24,
            }}
          >
            <CustomCard cardTitle="Stop Loss" rightComponent={<></>}>
              <Stack direction="column" gap={"20px"}>
                <Stack gap={"10px"}>
                  <CustomTab
                    options={stopLossOrderTypeOptions}
                    selectedOrderType={stopLossOrderType}
                    onSelect={handleStopLossOrderTypeChange}
                  />
                  <span
                    style={{
                      color: "#5d6e77",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    The order will be placed on the exchange when the price
                    meets Stop Loss conditions
                  </span>
                </Stack>
                <Stack direction="row" gap="10px">
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Entry Filled Price:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "500" }}
                  >
                    {selectedPosition.entry_order_filled_price}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Price
                  </Typography>
                  <Stack direction={"row"} alignItems="center" gap={"5px"}>
                    <Controller
                      name="stop_loss_price_type"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          size="small"
                          variant="outlined"
                          style={{ flex: 1 }}
                        >
                          {stopLossPriceOptions.map((option) => (
                            <MenuItem key={option} value={option.toLowerCase()}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <Stack style={{ flex: 4 }}>
                      <Stack>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ fontWeight: "600" }}
                        ></Typography>
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          gap={"20px"}
                        >
                          <TextField
                            type="text"
                            size="small"
                            variant="outlined"
                            placeholder="Stop Price"
                            onKeyDown={(e) => numbersOnly(e, "float", false)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {selectedPosition
                                    ? selectedPosition.symbol.split("/")[1]
                                    : "XXXX"}
                                </InputAdornment>
                              ),
                              style: { fontSize: 15 },
                            }}
                            style={{ flex: 3 }}
                            {...register("stop_loss_price")}
                          />
                          {stopLossPercentage && (
                            <span
                              style={{ color: "#1976d2", fontWeight: "600" }}
                            >
                              {stopLossPercentage}
                            </span>
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                  {errors.stop_loss_price?.message && (
                    <span
                      style={{
                        color: "#d32f2f",
                        fontSize: 14,
                        fontWeight: 500,
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      {errors.stop_loss_price?.message}
                    </span>
                  )}
                </Stack>
                {stopLossOrderType === "limit" && (
                  <Stack>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      style={{ fontWeight: "600" }}
                    >
                      Order Price
                    </Typography>
                    <Stack direction={"row"} alignItems="center" gap={"20px"}>
                      <TextField
                        type="text"
                        size="small"
                        variant="outlined"
                        placeholder="Order Price"
                        onKeyDown={(e) => numbersOnly(e, "float", false)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {selectedPosition
                                ? selectedPosition.symbol.split("/")[1]
                                : "XXXX"}
                            </InputAdornment>
                          ),
                          style: { fontSize: 15 },
                        }}
                        style={{ flex: 3 }}
                        {...register("stop_loss_limit_price")}
                      />
                      {stopLossLimitPercentage && (
                        <span style={{ color: "#1976d2", fontWeight: "600" }}>
                          {stopLossLimitPercentage}
                        </span>
                      )}
                    </Stack>
                    {errors.stop_loss_limit_price?.message && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: 14,
                          fontWeight: 500,
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        {errors.stop_loss_limit_price?.message}
                      </span>
                    )}
                  </Stack>
                )}
                <Stack style={{ width: "fit-content" }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    paddingBottom={"5px"}
                  >
                    <Stack direction="row" gap={"5px"}>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ fontWeight: "600" }}
                      >
                        Stop Loss timeout
                      </Typography>
                      <CustomTooltip text="Stop Loss timeout tooltip" />
                    </Stack>
                    <Controller
                      name="is_stop_loss_timeout"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          labelPlacement="start"
                          control={<CustomSwitch {...field} />}
                          style={{
                            paddingRight: "10px",
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack>
                    <TextField
                      name="stop_loss_timeout"
                      type="number"
                      placeholder="Stop Loss timeout"
                      variant="outlined"
                      size="small"
                      disabled={!isStopLossTimeout}
                      onKeyDown={(e) => numbersOnly(e, "int", false)}
                      style={{
                        cursor: isStopLossTimeout ? "default" : "not-allowed",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ gap: "10px" }}
                          >
                            <span>Sec</span>
                            <AddIcon
                              onClick={() => {
                                clearErrors("stop_loss_timeout");
                                if (!isStopLossTimeout) {
                                  return;
                                } else if (
                                  parseInt(
                                    getValues("stop_loss_timeout") || 0
                                  ) +
                                    1 <=
                                  1
                                ) {
                                  setValue("stop_loss_timeout", "1");
                                } else {
                                  setValue(
                                    "stop_loss_timeout",
                                    parseInt(getValues("stop_loss_timeout")) +
                                      1 +
                                      ""
                                  );
                                }
                              }}
                              width={"20px"}
                              height={"20px"}
                              style={{
                                cursor: isStopLossTimeout
                                  ? "pointer"
                                  : "not-allowed",
                              }}
                            />
                            <RemoveIcon
                              onClick={() => {
                                clearErrors("stop_loss_timeout");
                                if (
                                  !isStopLossTimeout ||
                                  isNaN(
                                    parseInt(getValues("stop_loss_timeout"))
                                  ) ||
                                  parseInt(getValues("stop_loss_timeout")) - 1 <
                                    1
                                ) {
                                  return;
                                } else {
                                  setValue(
                                    "stop_loss_timeout",
                                    parseInt(getValues("stop_loss_timeout")) -
                                      1 +
                                      ""
                                  );
                                }
                              }}
                              width={"20px"}
                              height={"20px"}
                              style={{
                                cursor:
                                  isStopLossTimeout &&
                                  parseInt(
                                    getValues("stop_loss_timeout") || 0
                                  ) > 1
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      {...register("stop_loss_timeout")}
                    />
                    {errors.stop_loss_timeout?.message && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: 14,
                          fontWeight: 500,
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        {errors.stop_loss_timeout?.message}
                      </span>
                    )}
                  </Stack>
                </Stack>

                <Stack style={{ width: "fit-content" }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    paddingBottom={"5px"}
                  >
                    <Stack direction="row" gap={"5px"}>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ fontWeight: "600" }}
                      >
                        Trailing Stop Loss
                      </Typography>
                      <CustomTooltip text="Trailing Stop Loss tooltip" />
                    </Stack>
                    <Controller
                      name="is_trailing_stop_loss"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          labelPlacement="start"
                          control={<CustomSwitch {...field} />}
                          style={{
                            paddingRight: "10px",
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack>
                    <TextField
                      name="trailing_stop_loss_percentage"
                      type="text"
                      placeholder="Trailing Stop Loss"
                      variant="outlined"
                      size="small"
                      disabled={!isTrailingStopLoss ? true : false}
                      onKeyDown={(e) => numbersOnly(e, "float", false)}
                      style={{
                        cursor: isTrailingStopLoss ? "default" : "not-allowed",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ gap: "10px" }}
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                      {...register("trailing_stop_loss_percentage")}
                    />
                    {errors.trailing_stop_loss_percentage?.message && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: 14,
                          fontWeight: 500,
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        {errors.trailing_stop_loss_percentage?.message}
                      </span>
                    )}
                  </Stack>
                </Stack>

                <Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    paddingBottom={"5px"}
                  >
                    <Stack direction="row" gap={"5px"}>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ fontWeight: "600" }}
                      >
                        Move to Breakeven
                      </Typography>
                      <CustomTooltip text="Move to Breakeven tooltip" />
                    </Stack>
                    <Controller
                      name="is_move_to_breakeven"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <FormControlLabel
                          labelPlacement="start"
                          control={<CustomSwitch {...field} />}
                          style={{
                            paddingRight: "10px",
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack>
                    <TextField
                      name="move_to_breakeven_points"
                      type="text"
                      placeholder="Move to Breakeven"
                      variant="outlined"
                      size="small"
                      disabled={!isMoveToBreakeven}
                      onKeyDown={(e) => numbersOnly(e, "float", false)}
                      style={{
                        cursor: isMoveToBreakeven ? "default" : "not-allowed",
                      }}
                      {...register("move_to_breakeven_points")}
                    />
                    {errors.move_to_breakeven_points?.message && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: 14,
                          fontWeight: 500,
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        {errors.move_to_breakeven_points?.message}
                      </span>
                    )}
                  </Stack>
                </Stack>
                <Stack direction="column" gap={"5px"}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Approximate Loss:
                  </Typography>
                  <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                    <img width="20px" height="20px" src="usdc.svg" alt="usdc" />
                    {stopLossAmount &&
                    stopLossAmountUSD &&
                    (stopLossOrderType === "limit"
                      ? stopLossLimitPercentage
                      : stopLossPercentage) ? (
                      <>
                        <span style={{ fontWeight: "600", color: "#e67387" }}>
                          {stopLossAmount}
                        </span>
                        <span style={{ fontWeight: "600", color: "#e67387" }}>
                          {stopLossAmountUSD}
                        </span>
                        <span style={{ fontWeight: "600", color: "#e67387" }}>
                          {stopLossOrderType === "limit"
                            ? stopLossLimitPercentage
                            : stopLossPercentage}
                        </span>
                      </>
                    ) : (
                      <span style={{ fontWeight: "600", color: "#e67387" }}>
                        ... ... ...
                      </span>
                    )}
                  </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" gap={"5px"}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Risk/Reward Ratio:
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    {stopLossAmount && takeProfitAmount ? (
                      <Stack direction={"row"} gap={0.6}>
                        <span>1</span>
                        <span>:</span>
                        <span>
                          {(
                            parseFloat(takeProfitAmount) /
                            (parseFloat(stopLossAmount) < 0
                              ? parseFloat(stopLossAmount) * -1
                              : parseFloat(stopLossAmount))
                          )
                            .toFixed(5)
                            .toString()
                            .replace(/\.?0*$/, "")}
                        </span>
                      </Stack>
                    ) : (
                      <span>... : ...</span>
                    )}
                  </Typography>
                </Stack>
                <Stack direction="row" gap={"5px"}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={formSubmitted}
                    style={{
                      cursor: formSubmitted ? "not-allowed" : "default",
                    }}
                    sx={{
                      fontWeight: 600,
                      flex: 1,
                    }}
                  >
                    {!selectedPosition.is_stop_loss ? "Attach" : "Edit"} SL
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{
                      fontWeight: 600,
                      flex: 1,
                    }}
                    onClick={() => {
                      reset();
                      setOpenStopLossModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </CustomCard>
          </form>
        </Modal>
      )}
      {/* End of Stop Loss Modal */}

      {/* Beginning of Trail SL modal */}
      {selectedPosition && openTrailStopLossModal && (
        <Modal
          open={openTrailStopLossModal}
          onClose={() => {
            reset();
            setSelectedPosition(null);
            setOpenTrailStopLossModal(false);
          }}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
              }
            }}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "background.paper",
              boxShadow: 24,
            }}
          >
            <CustomCard cardTitle="Trail Stop Loss" rightComponent={<></>}>
              <Stack style={{ width: "30vw" }} gap={"10px"}>
                {selectedPosition && (
                  <>
                    <Stack direction="row" gap={"5px"}>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ fontWeight: "600" }}
                      >
                        Entry Price:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ fontWeight: "500" }}
                      >
                        {selectedPosition.entry_order_filled_price}
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap={"5px"}>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ fontWeight: "600" }}
                      >
                        Stop Loss Price:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ fontWeight: "500" }}
                      >
                        {selectedPosition.stop_loss_price}
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap={"5px"}>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ fontWeight: "600" }}
                      >
                        Stop Loss Limit Price:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ fontWeight: "500" }}
                      >
                        {selectedPosition.stop_loss_limit_price}
                      </Typography>
                    </Stack>
                  </>
                )}
                <>
                  <Stack direction="row" alignItems="center">
                    <Stack direction="row" gap={"5px"}>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ fontWeight: "600" }}
                      >
                        Trailing Stop Loss
                      </Typography>
                      <CustomTooltip text="Trailing Stop Loss tooltip" />
                    </Stack>
                  </Stack>
                  <Stack>
                    <TextField
                      name="trailing_stop_loss_percentage"
                      type="text"
                      placeholder="Trailing Stop Loss"
                      variant="outlined"
                      size="small"
                      onKeyDown={(e) => numbersOnly(e, "float", false)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ gap: "10px" }}
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                      {...register("trailing_stop_loss_percentage")}
                    />
                    {errors.trailing_stop_loss_percentage?.message && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: 14,
                          fontWeight: 500,
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        {errors.trailing_stop_loss_percentage?.message}
                      </span>
                    )}
                  </Stack>
                </>
              </Stack>
              <Stack direction="row" gap={"5px"} paddingTop={"20px"}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={formSubmitted}
                  style={{ cursor: formSubmitted ? "not-allowed" : "default" }}
                  sx={{
                    fontWeight: 600,
                    flex: 1,
                  }}
                >
                  Trail SL
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{
                    fontWeight: 600,
                    flex: 1,
                  }}
                  onClick={() => {
                    reset();
                    setOpenTrailStopLossModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </CustomCard>
          </form>
        </Modal>
      )}
      {/* End of Trail SL modal */}
    </CustomCard>
  );
};

export default PositionsTableSection;
