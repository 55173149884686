import { default as axios } from "axios";
import { TRADE_API_URL } from "../api.routes";
import { getAuthToken } from "../auth/AuthContext";

export async function triggerTrade(data) {
  const res = await axios.post(TRADE_API_URL, data, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
}
