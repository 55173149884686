import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";

import CustomCard from "../components/CustomCard";

import { activate_account } from "../services/auth.service";

const ActivateAccountPage = () => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!token) {
      setMessage("Token missing");
      return;
    }
    activate_account(token)
      .then((response) => {
        if (response.data.success) {
          setSuccess(true);
          setMessage(response.data.msg);
        } else {
          setMessage(response.data.msg);
        }
      })
      .catch((err) => {
        setMessage(err.response.data.msg || err);
      });
  }, [token]);

  return (
    token && (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "50px",
          marginInline: "auto",
        }}
      >
        <CustomCard cardTitle="Account Activation" rightComponent={<></>}>
          <Stack minWidth="50vw" rowGap="10px">
            {<span>{message}</span>}
            {success ? (
              <Link to="/login" replace={true}>
                Click here to login.
              </Link>
            ) : (
              <></>
            )}
          </Stack>
        </CustomCard>
      </Stack>
    )
  );
};

export default ActivateAccountPage;
