import React from "react";
import { Card, Box, Stack, Typography, Divider } from "@mui/material";

const CustomCard = ({ cardTitle, rightComponent, children }) => {
  return (
    <Card variant="outlined">
      <Box sx={{ p: 1.5 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h6"
            component="div"
            style={{ fontWeight: "600" }}
          >
            {cardTitle}
          </Typography>
          <Typography variant="body2" component="div">
            {rightComponent}
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box sx={{ p: 1.5, pb: 3, maxHeight: "80vh", overflowY: "auto" }}>
        {children}
      </Box>
    </Card>
  );
};

export default CustomCard;
