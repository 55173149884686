import { default as axios } from "axios";
import { ATTACH_TP_ORDER_API_URL } from "../api.routes";
import { getAuthToken } from "../auth/AuthContext";

export async function attachTPOrder(data) {
  const res = await axios.post(ATTACH_TP_ORDER_API_URL, data, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
}
