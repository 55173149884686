import { default as axios } from "axios";
import { ATTACH_SL_ORDER_API_URL, TRAIL_SL_ORDER_API_URL } from "../api.routes";
import { getAuthToken } from "../auth/AuthContext";

export async function attachSLOrder(data) {
  const res = await axios.post(ATTACH_SL_ORDER_API_URL, data, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
}

export async function trailSLOrder(data) {
  const res = await axios.post(TRAIL_SL_ORDER_API_URL, data, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
}
