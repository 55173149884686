import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { CustomToast } from "../components/CustomToast";
import CustomCard from "../components/CustomCard";

import { useAuth } from "../auth/AuthContext";

import { auth_login, get_user_info } from "../services/auth.service";

const LoginPage = () => {
  const { token, login, logout } = useAuth();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (token) {
      get_user_info()
        .then((response) => {
          const role = response.data.role;
          login(token, role);
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            CustomToast({
              message: response.data.detail,
              type: "error",
            });
            logout();
          } else {
            CustomToast({
              message: response.data,
              type: "error",
            });
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const usersDataValues = {
    username: null,
    password: null,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...usersDataValues,
    },
  });

  const onSubmit = (data) => {
    setFormSubmitted(true);
    auth_login(data)
      .then((response) => {
        if (response.data.success) {
          const token = response.data.access_token;
          const user_role = response.data.role;
          CustomToast({
            message: response.data.msg,
            type: "success",
          });
          setFormSubmitted(false);
          login(token, user_role);
        } else {
          CustomToast({
            message: response.data.msg,
            type: "error",
          });
        }
        setFormSubmitted(false);
      })
      .catch((err) => {
        CustomToast({
          message: err.response.data.msg || err,
          type: "error",
        });
        setFormSubmitted(false);
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "50px",
      }}
    >
      <Stack minWidth="25vw" rowGap="15px">
        <CustomCard cardTitle="Login" rightComponent={<></>}>
          <Stack gap={"25px"}>
            <Stack direction="column" gap={"5px"}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom={"5px"}
              >
                <Stack direction="row" gap={"5px"}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Username
                  </Typography>
                </Stack>
              </Stack>
              <TextField
                name="username"
                placeholder="Username"
                type="text"
                size="small"
                {...register("username")}
              />
              {errors.username?.message && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: 14,
                    fontWeight: 500,
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {errors.username?.message}
                </span>
              )}
            </Stack>
            <Stack direction="column" gap={"5px"}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom={"5px"}
              >
                <Stack direction="row" gap={"5px"}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Password
                  </Typography>
                </Stack>
              </Stack>
              <TextField
                name="password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register("password")}
              />
              {errors.password?.message && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: 14,
                    fontWeight: 500,
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {errors.password?.message}
                </span>
              )}
            </Stack>
            <Stack direction="column" gap={"5px"}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formSubmitted}
                style={{
                  fontWeight: 600,
                  cursor: formSubmitted ? "not-allowed" : "default",
                }}
              >
                Login
              </Button>
            </Stack>
          </Stack>
        </CustomCard>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Link to="/forget-password" replace={true}>
              Forgot Password?
            </Link>
          </Stack>
          {/* <Stack direction="row" columnGap="5px">
            Not Registered?
            <Link to="/signup" replace={true}>
              Sign Up.
            </Link>
          </Stack> */}
        </Stack>
      </Stack>
    </form>
  );
};

export default LoginPage;
