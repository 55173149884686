import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ActivateAccountPage from "./pages/ActivateAccountPage";
import AdminPage from "./pages/AdminPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
// import SignUpPage from "./pages/SignUpPage";

import { AuthProvider, useAuth } from "./auth/AuthContext";

const PrivateRoute = () => {
  const { token } = useAuth();

  return token ? <Outlet /> : <Navigate to="/login" replace />;
};

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <ToastContainer />
      <AuthProvider>
        <CoinBaseAppRoutes />
      </AuthProvider>
    </BrowserRouter>
  );
};

const CoinBaseAppRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/login" />} />
    <Route path="/login" element={<LoginPage />} />
    {/* <Route path="/signup" element={<SignUpPage />} /> */}
    <Route path="/activate-user" element={<ActivateAccountPage />} />
    <Route path="/forget-password" element={<ForgetPasswordPage />} />
    <Route path="/reset-password" element={<ResetPasswordPage />} />

    <Route element={<PrivateRoute />}>
      <Route path="/admin" element={<AdminPage />} />
      <Route path="/home" element={<HomePage />} />
    </Route>

    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);
