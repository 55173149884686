import { default as axios } from "axios";
import { FORGET_PASSWORD_API_URL, RESET_PASSWORD_API_URL } from "../api.routes";

export async function forget_password(data) {
  const res = await axios.post(FORGET_PASSWORD_API_URL, data);
  return res;
}

export async function reset_password(data) {
  const res = await axios.post(RESET_PASSWORD_API_URL, data);
  return res;
}
