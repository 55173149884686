import React from "react";
import { Box, Button } from "@mui/material";

const CustomTab = ({
  options,
  selectedOrderType,
  onSelect,
  isDisabled = false,
}) => {
  return (
    <Box
      display="flex"
      sx={{
        border: isDisabled ? "1px solid #9fa8b2" : "1px solid #5d6e77",
        borderRadius: 1,
        overflow: "hidden",
        cursor: isDisabled ? "not-allowed" : "pointer",
      }}
    >
      {options.map((option, index) => (
        <Button
          key={index}
          disabled={isDisabled}
          onClick={() => onSelect(option.value)}
          style={{
            flex: 1,
            paddingInline: "20px",
            paddingBlock: "10px",
            fontSize: "16px",
            fontWeight: 500,
            textTransform: "capitalize",
            color:
              selectedOrderType === option.value
                ? "#ffffff"
                : isDisabled
                ? "#9fa8b2"
                : "#5d6e77",
            backgroundColor:
              selectedOrderType === option.value
                ? isDisabled
                  ? "#9fa8b2"
                  : "#5d6e77"
                : "#ffffff",
            borderTopLeftRadius: index === 0 ? 4 : 0,
            borderBottomLeftRadius: index === 0 ? 4 : 0,
            borderTopRightRadius: index === options.length - 1 ? 4 : 0,
            borderBottomRightRadius: index === options.length - 1 ? 4 : 0,
            borderRight:
              index !== options.length - 1
                ? isDisabled
                  ? "1px solid #9fa8b2"
                  : "1px solid #5d6e77"
                : "none",
            transition: "background-color 0.3s, color 0.3s",
          }}
        >
          {option.label}
        </Button>
      ))}
    </Box>
  );
};

export default CustomTab;
