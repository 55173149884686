export const numbersOnly = (event, numberType, allowNegative) => {
  const str = event.key;

  if (str.length > 1) {
    return;
  } else if (/[a-zA-Z]/.test(str)) {
    // Allow key combinations like Ctrl + char, Meta + char, Alt + char
    if (event.ctrlKey || event.metaKey || event.altKey) {
      return;
    } else {
      event.preventDefault();
      return;
    }
  }
  let regex;

  if (numberType === "int") {
    regex = allowNegative ? /[0-9-]/ : /[0-9]/;
  } else if (numberType === "float") {
    regex = allowNegative ? /[-.0-9]/ : /[.0-9]/;
    const value = event.target.value;
    if ((str === "." || str === "-") && value.includes(str)) {
      event.preventDefault();
      return;
    }
    if (str === "." && value === "") {
      event.preventDefault();
      return;
    }
  } else {
    console.error("Invalid numberType. Must be 'int' or 'float'");
    throw new Error("Invalid numberType. Must be 'int' or 'float'");
  }

  if (!regex.test(str)) {
    event.preventDefault();
  }
};
