import { default as axios } from "axios";
import { SYMBOLS_API_URL } from "../api.routes";
import { getAuthToken } from "../auth/AuthContext";

export async function getSymbols() {
  const res = await axios.get(SYMBOLS_API_URL, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
}
