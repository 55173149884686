import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { CustomToast } from "../components/CustomToast";
import CustomCard from "../components/CustomCard";

import { isStrongPassword } from "../validator/passwordValidator";

import { reset_password } from "../services/password.service";

const ResetPasswordPage = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");

  const resetPasswordValues = {
    token: token,
    password: null,
    confirm_password: null,
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...resetPasswordValues,
    },
  });

  const onSubmit = (data) => {
    setFormSubmitted(true);

    if (!data["token"]) {
      CustomToast({
        message: "Reset Token not found.",
        type: "error",
      });
      setFormSubmitted(false);
      return;
    }

    const requiredFields = [
      { name: "password", message: "Password must be provided" },
      {
        name: "confirm_password",
        message: "Confirm Password must be provided",
      },
    ];

    for (const field of requiredFields) {
      if (!data[field.name]) {
        setError(
          field.name,
          { type: "required", message: field.message },
          { shouldFocus: true }
        );
        setFormSubmitted(false);
        return;
      }
    }

    if (data["password"] !== data["confirm_password"]) {
      setError(
        "confirm_password",
        {
          type: "required",
          message: "Confirm Password should be same as Password",
        },
        { shouldFocus: true }
      );
      setFormSubmitted(false);
      return;
    } else {
      delete data["confirm_password"];
    }

    if (!isStrongPassword(data["password"])) {
      setError(
        "password",
        {
          type: "validate",
          message:
            "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character",
        },
        { shouldFocus: true }
      );
      setFormSubmitted(false);
      return;
    }

    reset_password(data)
      .then((response) => {
        if (response.data.success) {
          CustomToast({
            message: response.data.msg,
            type: "success",
          });
          setFormSubmitted(false);
          navigate("/login", { replace: true });
        } else {
          CustomToast({
            message: response.data.msg,
            type: "error",
          });
        }
        setFormSubmitted(false);
      })
      .catch((err) => {
        CustomToast({
          message: err.response.data.msg || err,
          type: "error",
        });
        setFormSubmitted(false);
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "50px",
      }}
    >
      <Stack width="25vw">
        <CustomCard cardTitle="Reset Password" rightComponent={<></>}>
          <Stack gap={"25px"}>
            <Stack direction="column" gap={"5px"}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom={"5px"}
              >
                <Stack direction="row" gap={"5px"}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Password
                  </Typography>
                </Stack>
              </Stack>
              <TextField
                name="password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register("password")}
              />
              {errors.password?.message && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: 14,
                    fontWeight: 500,
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {errors.password?.message}
                </span>
              )}
            </Stack>
            <Stack direction="column" gap={"5px"}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom={"5px"}
              >
                <Stack direction="row" gap={"5px"}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Confirm Password
                  </Typography>
                </Stack>
              </Stack>
              <TextField
                name="confirm_password"
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register("confirm_password")}
              />
              {errors.confirm_password?.message && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: 14,
                    fontWeight: 500,
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {errors.confirm_password?.message}
                </span>
              )}
            </Stack>
            <Stack direction="column" gap={"5px"}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formSubmitted}
                style={{
                  fontWeight: 600,
                  cursor: formSubmitted ? "not-allowed" : "default",
                }}
              >
                Update Password
              </Button>
            </Stack>
          </Stack>
        </CustomCard>
      </Stack>
    </form>
  );
};

export default ResetPasswordPage;
