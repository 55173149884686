import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import CustomCard from "./CustomCard";
import CustomLoader from "./CustomLoader";
import CustomSwitch from "./CustomSwitch";
import CustomTab from "./CustomTab";
import CustomTooltip from "./CustomTooltip";
import { CustomToast } from "./CustomToast";

import { getSymbols } from "../services/symbols.service";
import { triggerTrade } from "../services/trade.service";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";

import { ReactComponent as WalletIcon } from "../assets/wallet.svg";

import { numbersOnly } from "../validator/numberValidator";
import { useAuth } from "../auth/AuthContext";

const EntryOrderSection = ({
  ltp,
  walletBalance,
  isWalletBalanceLoading,
  onSymbolChange,
}) => {
  const { logout } = useAuth();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [selectedBaseSymbol, setSelectedBaseSymbol] = useState(null);
  const [selectedQuoteSymbol, setSelectedQuoteSymbol] = useState("USDC");
  const [symbolsOptions, setSymbolsOptions] = useState([]);
  const [isSymbolOptionsLoading, setIsSymbolOptionsLoading] = useState(true);

  const orderTypeOptions = [
    { label: "Limit", value: "limit" },
    { label: "Market", value: "market" },
    { label: "Cond.", value: "cond." },
  ];
  const [orderType, setOrderType] = useState("limit");
  const orderTypeMessages = {
    "limit": "Will be placed on the exchange order book at given order price. Can't buy higher than current price",
    "market": "Will buy at actual rates after the trade is created",
    "cond.": "Triggers when price reaches the set level",
  };

  const conditionOrderTypeOptions = [
    { label: "Cond. Limit Order", value: "cond. limit" },
    { label: "Cond. Market Order", value: "cond. market" },
  ];
  const [conditionOrderType, setConditionOrderType] = useState("cond. limit");

  const [totalAmount, setTotalAmount] = useState(null);

  const takeProfitOrderTypeOptions = [
    { label: "Limit Order", value: "limit" },
    { label: "Market Order", value: "market" },
  ];
  const [takeProfitOrderType, setTakeProfitOrderType] = useState("limit");
  const [takeProfitAmount, setTakeProfitAmount] = useState(null);
  const [takeProfitAmountUSD, setTakeProfitAmountUSD] = useState(null); // For now same as takeProfitAmount
  const [takeProfitPercentage, setTakeProfitPercentage] = useState(null);

  const stopLossOrderTypeOptions = [
    { label: "Cond. Limit Order", value: "limit" },
    { label: "Cond. Market Order", value: "market" },
  ];
  const [stopLossOrderType, setStopLossOrderType] = useState("limit");
  const stopLossPriceOptions = ["Last", "Open"];
  const [stopLossAmount, setStopLossAmount] = useState(null);
  const [stopLossAmountUSD, setStopLossAmountUSD] = useState(null); // For now same as stopLossAmount
  const [stopLossPercentage, setStopLossPercentage] = useState(null);
  const [stopLossLimitPercentage, setStopLossLimitPercentage] = useState(null);

  const symbolSectionValues = {
    symbol: null,
  };
  const useExistingAssetsSectionValues = {
    use_existing_assets: false,
    assets_amount_to_use: null,
  };
  const buyPriceSectionValues = {
    entry_order_type: null,
    entry_order_quantity: null,
    entry_order_price: null,
    entry_order_trigger_price: null,
    is_entry_trailing_buy: false,
    trailing_buy_percentage: null,
  };
  const takeProfitSectionValues = {
    is_take_profit: false,
    take_profit_order_type: null,
    is_trailing_take_profit_for_last_target: false,
    take_profit_max_price_with_deviation_percentage: 0,
    take_profit: [],
  };
  const stopLossSectionValues = {
    is_stop_loss: false,
    stop_loss_order_type: null,
    stop_loss_price_type: stopLossPriceOptions[0].toLowerCase(),
    stop_loss_price: null,
    stop_loss_limit_price: null,
    is_stop_loss_timeout: false,
    stop_loss_timeout: null,
    is_trailing_stop_loss: false,
    trailing_stop_loss_percentage: null,
    is_move_to_breakeven: false,
    move_to_breakeven_points: null,
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    setFocus,
    getFieldState,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...symbolSectionValues,
      ...useExistingAssetsSectionValues,
      ...buyPriceSectionValues,
      ...takeProfitSectionValues,
      ...stopLossSectionValues,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "take_profit",
  });

  let isEntryTrailingBuy = watch("is_entry_trailing_buy");
  let useExistingAssets = watch("use_existing_assets");
  let assetsAmountToUse = watch("assets_amount_to_use");
  let isTakeProfit = watch("is_take_profit");
  let isTrailingTakeProfitForLastTarget = watch(
    "is_trailing_take_profit_for_last_target"
  );
  let isStopLoss = watch("is_stop_loss");
  let isStopLossTimeout = watch("is_stop_loss_timeout");
  let isTrailingStopLoss = watch("is_trailing_stop_loss");
  let isMoveToBreakeven = watch("is_move_to_breakeven");

  let entryOrderQuantity = watch("entry_order_quantity");
  let entryOrderPrice = watch("entry_order_price");
  let entryOrderTriggerPrice = watch("entry_order_trigger_price");
  let takeProfits = watch("take_profit");
  let stopLossPrice = watch("stop_loss_price");
  let stopLossLimitPrice = watch("stop_loss_limit_price");

  const handleOrderTypeChange = (value) => {
    setOrderType(value);
  };

  const handleConditionalOrderTypeChange = (value) => {
    setConditionOrderType(value);
  };

  const handleTakeProfitOrderTypeChange = (selectedIndex) => {
    setTakeProfitOrderType(selectedIndex);
  };

  const handleStopLossOrderTypeChange = (selectedIndex) => {
    setStopLossOrderType(selectedIndex);
  };

  const onSubmit = (data) => {
    setFormSubmitted(true);
    const filteredFormData = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => value !== null && value !== "" && value !== undefined
      )
    );
    data = filteredFormData;
    if (orderType === "cond.") {
      data["entry_order_type"] = conditionOrderType;
    } else {
      data["entry_order_type"] = orderType;
    }

    if (selectedBaseSymbol && selectedQuoteSymbol) {
      data["symbol"] = selectedBaseSymbol + "/" + selectedQuoteSymbol;
    } else {
      setError("symbol", {
        type: "required",
        message: "This input is required.",
      });
      setFocus("symbol");
      setFormSubmitted(false);
      return;
    }

    if (data.use_existing_assets) {
      if (data.assets_amount_to_use) {
        if (parseFloat(data.assets_amount_to_use) <= 0) {
          setError("assets_amount_to_use", {
            type: "min",
            message: "Assets amount to use must be > 0",
          });
          setFocus("assets_amount_to_use");
          setFormSubmitted(false);
          return;
        } else {
          data.assets_amount_to_use = parseFloat(data.assets_amount_to_use);
          data.entry_order_type = "market";
          if ("entry_order_quantity" in data) {
            delete data["entry_order_quantity"];
          }
        }
      } else {
        setError("assets_amount_to_use", {
          type: "required",
          message: "This input is required.",
        });
        setFocus("assets_amount_to_use");
        setFormSubmitted(false);
        return;
      }
    } else {
      if ("assets_amount_to_use" in data) {
        delete data["assets_amount_to_use"];
      }

      if (data.entry_order_quantity) {
        if (parseFloat(data.entry_order_quantity) <= 0) {
          setError("entry_order_quantity", {
            type: "min",
            message: "Order Qty. must be > 0",
          });
          setFocus("entry_order_quantity");
          setFormSubmitted(false);
          return;
        } else {
          data.entry_order_quantity = parseFloat(data.entry_order_quantity);
        }
      } else {
        setError("entry_order_quantity", {
          type: "required",
          message: "This input is required.",
        });
        setFocus("entry_order_quantity");
        setFormSubmitted(false);
        return;
      }
    }

    if (!data["entry_order_type"].includes("market")) {
      if (data.entry_order_price) {
        if (parseFloat(data.entry_order_price) <= 0) {
          setError("entry_order_price", {
            type: "min",
            message: "Order Price must be > 0",
          });
          setFocus("entry_order_price");
          setFormSubmitted(false);
          return;
        } else {
          data.entry_order_price = parseFloat(data.entry_order_price);
        }
      } else {
        setError("entry_order_price", {
          type: "required",
          message: "This input is required.",
        });
        setFocus("entry_order_price");
        setFormSubmitted(false);
        return;
      }
    } else {
      if ("entry_order_price" in data) {
        delete data["entry_order_price"];
      }
    }

    if (data["entry_order_type"].includes("cond.")) {
      if (data.entry_order_trigger_price) {
        if (parseFloat(data.entry_order_trigger_price) <= 0) {
          setError("entry_order_trigger_price", {
            type: "min",
            message: "Order Trigger Price must be > 0",
          });
          setFocus("entry_order_trigger_price");
          setFormSubmitted(false);
          return;
        } else {
          data.entry_order_trigger_price = parseFloat(
            data.entry_order_trigger_price
          );
        }
      } else {
        setError("entry_order_trigger_price", {
          type: "required",
          message: "This input is required.",
        });
        setFocus("entry_order_trigger_price");
        setFormSubmitted(false);
        return;
      }
    } else {
      if ("entry_order_trigger_price" in data) {
        delete data["entry_order_trigger_price"];
      }
    }

    if (data.is_entry_trailing_buy && data.entry_order_type !== "market") {
      if (data.trailing_buy_percentage) {
        if (parseFloat(data.trailing_buy_percentage) <= 0) {
          setError("trailing_buy_percentage", {
            type: "min",
            message: "Trailing Buy must be > 0",
          });
          setFocus("trailing_buy_percentage");
          setFormSubmitted(false);
          return;
        } else {
          data.trailing_buy_percentage = parseFloat(
            data.trailing_buy_percentage
          );
        }
      } else {
        setError("trailing_buy_percentage", {
          type: "required",
          message: "This input is required.",
        });
        setFocus("trailing_buy_percentage");
        setFormSubmitted(false);
        return;
      }
    } else {
      data["is_entry_trailing_buy"] = false;
      if ("trailing_buy_percentage" in data) {
        delete data["trailing_buy_percentage"];
      }
    }

    if (data.is_take_profit) {
      data["take_profit_order_type"] = takeProfitOrderType;

      if (data.take_profit.length > 0) {
        let error_occurred = false;
        let total_tp_quantity = 0;
        data.take_profit.forEach((item, index) => {
          const { price, quantity } = item;
          if (price === null || price === "" || price === undefined) {
            setError(`take_profit.${index}.price`, {
              type: "required",
              message: "Price is required",
            });
            setFocus(`take_profit.${index}.price`);
            error_occurred = true;
            setFormSubmitted(false);
            return;
          }
          if (price <= 0) {
            setError(`take_profit.${index}.price`, {
              type: "min",
              message: "Price must be > 0",
            });
            setFocus(`take_profit.${index}.price`);
            error_occurred = true;
            setFormSubmitted(false);
            return;
          }
          item.price = parseFloat(price);
          if (quantity === null || quantity === "" || quantity === undefined) {
            setError(`take_profit.${index}.quantity`, {
              type: "required",
              message: "Quantity is required",
            });
            setFocus(`take_profit.${index}.quantity`);
            error_occurred = true;
            setFormSubmitted(false);
            return;
          }
          if (quantity <= 0) {
            setError(`take_profit.${index}.quantity`, {
              type: "min",
              message: "Quantity must be > 0",
            });
            setFocus(`take_profit.${index}.quantity`);
            error_occurred = true;
            setFormSubmitted(false);
            return;
          }
          item.quantity = parseFloat(quantity);
          total_tp_quantity = total_tp_quantity + parseFloat(quantity);
        });
        if (total_tp_quantity !== parseFloat(100)) {
          setError(`take_profit_add`, {
            type: "min",
            message: "Take Profit total quantity must be equal to 100%",
          });
          error_occurred = true;
        }
        if (error_occurred) {
          setFormSubmitted(false);
          return;
        }
      } else {
        setError("take_profit_add", {
          type: "min",
          message: "At least 1 Take Profit entry should be added",
        });
        setFocus("take_profit_add");
        setFormSubmitted(false);
        return;
      }

      if (data["take_profit_order_type"] === "limit") {
        if (data.is_trailing_take_profit_for_last_target) {
          if (
            isNaN(
              parseFloat(data.take_profit_max_price_with_deviation_percentage)
            ) ||
            parseFloat(data.take_profit_max_price_with_deviation_percentage) >=
              0
          ) {
            setError("take_profit_max_price_with_deviation_percentage", {
              type: "max",
              message: "Follow max price with deviation (%) must be < 0",
            });
            setFocus("take_profit_max_price_with_deviation_percentage");
            setFormSubmitted(false);
            return;
          } else {
            data.take_profit_max_price_with_deviation_percentage = parseFloat(
              data.take_profit_max_price_with_deviation_percentage
            );
          }
        } else {
          if ("take_profit_max_price_with_deviation_percentage" in data) {
            delete data["take_profit_max_price_with_deviation_percentage"];
          }
        }
      } else {
        if ("is_trailing_take_profit_for_last_target" in data) {
          delete data["is_trailing_take_profit_for_last_target"];
        }
        if ("take_profit_max_price_with_deviation_percentage" in data) {
          delete data["take_profit_max_price_with_deviation_percentage"];
        }
      }
    } else {
      for (const key in takeProfitSectionValues) {
        if (key !== "is_take_profit" && key in data) {
          delete data[key];
        }
      }
    }

    if (data.is_stop_loss) {
      data["stop_loss_order_type"] = stopLossOrderType;

      if (data.stop_loss_price) {
        if (parseFloat(data.stop_loss_price) <= 0) {
          setError("stop_loss_price", {
            type: "min",
            message: "Stop loss price must be > 0",
          });
          setFocus("take_profit_max_price_with_deviation_percentage");
          setFormSubmitted(false);
          return;
        } else {
          data.stop_loss_price = parseFloat(data.stop_loss_price);
        }
      } else {
        setError("stop_loss_price", {
          type: "required",
          message: "This input is required.",
        });
        setFocus("stop_loss_price");
        setFormSubmitted(false);
        return;
      }

      if (data["stop_loss_order_type"] === "limit") {
        if (data.stop_loss_limit_price) {
          if (parseFloat(data.stop_loss_limit_price) <= 0) {
            setError("stop_loss_limit_price", {
              type: "min",
              message: "Order Price must be > 0",
            });
            setFocus("stop_loss_limit_price");
            setFormSubmitted(false);
            return;
          } else {
            data.stop_loss_limit_price = parseFloat(data.stop_loss_limit_price);
          }
        } else {
          setError("stop_loss_limit_price", {
            type: "required",
            message: "This input is required.",
          });
          setFocus("stop_loss_limit_price");
          setFormSubmitted(false);
          return;
        }
      } else {
        if ("stop_loss_limit_price" in data) {
          delete data["stop_loss_limit_price"];
        }
      }

      if (data.is_stop_loss_timeout) {
        if (data.stop_loss_timeout) {
          if (parseInt(data.stop_loss_timeout) <= 0) {
            setError("stop_loss_timeout", {
              type: "min",
              message: "Stop Loss timeout must be > 0",
            });
            setFocus("stop_loss_timeout");
            setFormSubmitted(false);
            return;
          } else {
            data.stop_loss_timeout = parseInt(data.stop_loss_timeout);
          }
        } else {
          setError("stop_loss_timeout", {
            type: "required",
            message: "This input is required.",
          });
          setFocus("stop_loss_timeout");
          setFormSubmitted(false);
          return;
        }
      } else {
        if ("stop_loss_timeout" in data) {
          delete data["stop_loss_timeout"];
        }
      }

      if (data.is_trailing_stop_loss) {
        if (data.trailing_stop_loss_percentage) {
          if (parseFloat(data.trailing_stop_loss_percentage) <= 0) {
            setError("trailing_stop_loss_percentage", {
              type: "min",
              message: "Trailing stop loss(%) must be > 0",
            });
            setFocus("trailing_stop_loss_percentage");
            setFormSubmitted(false);
            return;
          } else {
            data.trailing_stop_loss_percentage = parseFloat(
              data.trailing_stop_loss_percentage
            );
          }
        } else {
          setError("trailing_stop_loss_percentage", {
            type: "required",
            message: "This input is required.",
          });
          setFocus("trailing_stop_loss_percentage");
          setFormSubmitted(false);
          return;
        }
      } else {
        if ("trailing_stop_loss_percentage" in data) {
          delete data["trailing_stop_loss_percentage"];
        }
      }

      if (data.is_move_to_breakeven) {
        if (data.move_to_breakeven_points) {
          if (parseFloat(data.move_to_breakeven_points) <= 0) {
            setError("move_to_breakeven_points", {
              type: "min",
              message: "Move to breakeven points must be > 0",
            });
            setFocus("move_to_breakeven_points");
            setFormSubmitted(false);
            return;
          } else {
            data.move_to_breakeven_points = parseFloat(
              data.move_to_breakeven_points
            );
          }
        } else {
          setError("move_to_breakeven_points", {
            type: "required",
            message: "This input is required.",
          });
          setFocus("move_to_breakeven_points");
          setFormSubmitted(false);
          return;
        }
      } else {
        if ("move_to_breakeven_points" in data) {
          delete data["move_to_breakeven_points"];
        }
      }
    } else {
      for (const key in stopLossSectionValues) {
        if (key !== "is_stop_loss" && key in data) {
          delete data[key];
        }
      }
    }

    // TODO: Later decide what to do with market take_profit or stop_loss orders
    if (data["take_profit_order_type"] === "market") {
      data["take_profit_order_type"] = "limit";
    }
    // TODO: For now making limit sl even on selecting market order because not supported by ccxt coinbasepro
    if (data["stop_loss_order_type"] === "market") {
      data["stop_loss_order_type"] = "limit";
      data["stop_loss_limit_price"] = data["stop_loss_price"];
    }

    triggerTrade(data)
      .then((response) => {
        if (response.data.success) {
          CustomToast({
            message: response.data.msg,
            type: "success",
          });
        } else {
          CustomToast({
            message: response.data.msg,
            type: "error",
          });
        }
        setFormSubmitted(false);
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          CustomToast({
            message: response.data.detail,
            type: "error",
          });
          logout();
        } else {
          CustomToast({
            message: response.data,
            type: "error",
          });
        }
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    // Get Symbols from Coinbase
    setIsSymbolOptionsLoading(true);
    getSymbols()
      .then((response) => {
        if (response.data.success) {
          setSymbolsOptions(response.data.symbols);
        } else {
          CustomToast({
            message: response.data.msg,
            type: "error",
          });
        }
        setIsSymbolOptionsLoading(false);
      })
      .catch(({ response }) => {
        setIsSymbolOptionsLoading(false);
        if (response.status === 401) {
          CustomToast({
            message: response.data.detail,
            type: "error",
          });
          logout();
        } else {
          CustomToast({
            message: response.data,
            type: "error",
          });
        }
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedBaseSymbol && selectedQuoteSymbol) {
      onSymbolChange(selectedBaseSymbol + "/" + selectedQuoteSymbol);
    } else {
      onSymbolChange(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBaseSymbol, selectedQuoteSymbol]);

  useEffect(() => {
    if (fields.length > 0) {
      clearErrors("take_profit_add");
    }
    // eslint-disable-next-line
  }, [fields]);

  useEffect(() => {
    if (useExistingAssets) {
      setTotalAmount(assetsAmountToUse);
      return;
    }

    if (parseFloat(entryOrderQuantity) <= 0) {
      setTotalAmount(null);
      return;
    }

    if (orderType !== "market") {
      let nonMarketOrderType = null;
      if (orderType === "cond.") {
        nonMarketOrderType = conditionOrderType;
      } else {
        nonMarketOrderType = orderType;
      }

      if (nonMarketOrderType.includes("limit")) {
        // "limit" or "cond. limit" type
        if (parseFloat(entryOrderPrice) > 0) {
          let total_amount = entryOrderQuantity * entryOrderPrice;
          setTotalAmount(total_amount);
        } else {
          setTotalAmount(null);
        }
      } else {
        // "cond. market" type
        if (parseFloat(entryOrderTriggerPrice) > 0) {
          let total_amount = entryOrderQuantity * entryOrderTriggerPrice;
          setTotalAmount(total_amount);
        } else {
          setTotalAmount(null);
        }
      }
    } else {
      if (ltp) {
        let total_amount = entryOrderQuantity * ltp;
        setTotalAmount(total_amount);
      } else {
        setTotalAmount(null);
      }
    }
  }, [
    orderType,
    conditionOrderType,
    ltp,
    entryOrderQuantity,
    entryOrderPrice,
    entryOrderTriggerPrice,
    useExistingAssets,
    assetsAmountToUse,
  ]);

  useEffect(() => {
    let totalProfit = 0.0;
    let totalProfitUSD = null; // For now same as totalProfit as working on USD based coins
    let totalProfitPercentage = null;
    let totalInvestment = null;
    let buyPrice = null;
    let entryOrderQty = null;

    if (useExistingAssets) {
      entryOrderQty = assetsAmountToUse / ltp;
    } else {
      entryOrderQty = entryOrderQuantity;
    }

    if (parseFloat(entryOrderQty) <= 0) {
      setTakeProfitAmount(null);
      setTakeProfitAmountUSD(null);
      setTakeProfitPercentage(null);
      return;
    }

    if (orderType !== "market" && !useExistingAssets) {
      let nonMarketOrderType = null;
      if (orderType === "cond.") {
        nonMarketOrderType = conditionOrderType;
      } else {
        nonMarketOrderType = orderType;
      }

      if (nonMarketOrderType.includes("limit")) {
        // "limit" or "cond. limit" type
        buyPrice = entryOrderPrice;
      } else {
        // "cond. market" type
        buyPrice = entryOrderTriggerPrice;
      }
    } else {
      buyPrice = ltp;
    }

    if (buyPrice && parseFloat(buyPrice) > 0) {
      totalInvestment = entryOrderQty * buyPrice;
    } else {
      setTakeProfitAmount(null);
      setTakeProfitAmountUSD(null);
      setTakeProfitPercentage(null);
      return;
    }

    takeProfits.forEach((item) => {
      if (parseFloat(item.price) > 0 && parseFloat(item.quantity) > 0) {
        const profit =
          (parseFloat(item.price) - buyPrice) *
          (entryOrderQty * (parseFloat(item.quantity) / 100));
        totalProfit += profit;
      } else {
        setTakeProfitAmount(null);
        setTakeProfitAmountUSD(null);
        setTakeProfitPercentage(null);
        return;
      }
    });
    totalProfitUSD = totalProfit
      .toFixed(5)
      .toString()
      .replace(/\.?0*$/, "");
    totalProfitPercentage = (totalProfit / totalInvestment) * 100;
    totalProfitPercentage = totalProfitPercentage
      .toFixed(5)
      .toString()
      .replace(/\.?0*$/, "");

    if (totalProfit > 0) {
      totalProfit = "+" + totalProfit.toString();
    }
    if (totalProfitUSD > 0) {
      totalProfitUSD = "+" + totalProfitUSD.toString();
    }
    if (totalProfitPercentage > 0) {
      totalProfitPercentage = "+" + totalProfitPercentage.toString();
    }

    setTakeProfitAmount(totalProfit);
    setTakeProfitAmountUSD(totalProfitUSD + "$");
    setTakeProfitPercentage(totalProfitPercentage + "%");
  }, [
    orderType,
    conditionOrderType,
    ltp,
    entryOrderQuantity,
    entryOrderPrice,
    entryOrderTriggerPrice,
    takeProfits,
    useExistingAssets,
    assetsAmountToUse,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getFieldState("take_profit"),
  ]);

  useEffect(() => {
    let slAmount = 0.0;
    let slAmountUSD = null; // For now same as slAmount as working on USD based coins
    let slPercentage = null;
    let slLimitPercentage = null;
    let buyPrice = null;
    let exitPrice = null;
    let entryOrderQty = entryOrderQuantity;

    if (useExistingAssets) {
      entryOrderQty = assetsAmountToUse / ltp;
    }

    if (parseFloat(entryOrderQty) <= 0) {
      setStopLossPercentage(null);
      setStopLossLimitPercentage(null);
      setStopLossAmount(null);
      setStopLossAmountUSD(null);
      return;
    }

    if (orderType !== "market" && !useExistingAssets) {
      let nonMarketOrderType = null;
      if (orderType === "cond.") {
        nonMarketOrderType = conditionOrderType;
      } else {
        nonMarketOrderType = orderType;
      }

      if (nonMarketOrderType.includes("limit")) {
        // "limit" or "cond. limit" type
        buyPrice = entryOrderPrice;
      } else {
        // "cond. market" type
        buyPrice = entryOrderTriggerPrice;
      }
    } else {
      // "market" type or useExistingAssets enabled
      buyPrice = ltp;
    }

    if (parseFloat(buyPrice) <= 0) {
      setStopLossPercentage(null);
      setStopLossLimitPercentage(null);
      setStopLossAmount(null);
      setStopLossAmountUSD(null);
      return;
    }

    if (parseFloat(stopLossPrice) > 0) {
      slPercentage = ((stopLossPrice - buyPrice) / buyPrice) * 100;
      slPercentage = slPercentage.toFixed(2) + "%";
      setStopLossPercentage(slPercentage);
    } else {
      setStopLossPercentage(null);
    }

    if (stopLossOrderType === "limit" && parseFloat(stopLossLimitPrice) > 0) {
      slLimitPercentage = ((stopLossLimitPrice - buyPrice) / buyPrice) * 100;
      slLimitPercentage = slLimitPercentage.toFixed(2) + "%";
      setStopLossLimitPercentage(slLimitPercentage);
    } else {
      setStopLossLimitPercentage(null);
    }

    if (stopLossOrderType === "limit") {
      exitPrice = stopLossLimitPrice;
    } else {
      exitPrice = stopLossPrice;
    }

    if (exitPrice && parseFloat(exitPrice) > 0) {
      slAmount = (exitPrice - buyPrice) * entryOrderQty;
      slAmountUSD =
        slAmount
          .toFixed(5)
          .toString()
          .replace(/\.?0*$/, "") + "$"; // For now same as slAmount as working on USD based coins
      setStopLossAmount(slAmount);
      setStopLossAmountUSD(slAmountUSD);
    } else {
      setStopLossAmount(null);
      setStopLossAmountUSD(null);
    }
  }, [
    orderType,
    conditionOrderType,
    ltp,
    stopLossOrderType,
    useExistingAssets,
    assetsAmountToUse,
    entryOrderQuantity,
    entryOrderPrice,
    entryOrderTriggerPrice,
    stopLossPrice,
    stopLossLimitPrice,
  ]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {/* SymbolSection */}
          <CustomCard cardTitle="Symbol">
            <Stack direction="column" gap={"5px"}>
              <>
                <Autocomplete
                  options={symbolsOptions}
                  loading={isSymbolOptionsLoading}
                  onChange={(event, value) => {
                    clearErrors("symbol");
                    if (value === null) {
                      setSelectedBaseSymbol(null);
                      setSelectedQuoteSymbol("USDC");
                      return;
                    }
                    const [baseSymbol, quoteSymbol] = value["value"].split("/");
                    setSelectedBaseSymbol(baseSymbol);
                    setSelectedQuoteSymbol(quoteSymbol);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  renderInput={(params) => (
                    <TextField
                      name="symbol"
                      {...params}
                      size="small"
                      placeholder={"Search Symbol"}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isSymbolOptionsLoading ? <CustomLoader /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      {...register("symbol")}
                    />
                  )}
                />
                {errors.symbol?.message && (
                  <span
                    style={{
                      color: "#d32f2f",
                      fontSize: 14,
                      fontWeight: 500,
                      marginTop: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {errors.symbol?.message}
                  </span>
                )}
              </>
              {ltp && (
                <Typography
                  variant="subtitle2"
                  component="div"
                  style={{ fontWeight: "600" }}
                  color={"#898989"}
                >
                  LTP: {ltp}
                </Typography>
              )}
            </Stack>
          </CustomCard>
          {/* End of SymbolSection */}

          {/* UnitsSection */}
          <CustomCard
            cardTitle="Units"
            rightComponent={
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {isWalletBalanceLoading ? (
                  <CustomLoader />
                ) : (
                  <>
                    <WalletIcon width={"20px"} height={"20px"} />
                    <span style={{ color: "#1976d2", fontWeight: 600 }}>
                      {walletBalance} USDC
                    </span>
                  </>
                )}
                <CustomTooltip text="Wallet tooltip" />
              </div>
            }
          >
            <Stack direction="column" gap={"5px"}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom={"5px"}
              >
                <Stack direction="row" gap={"5px"}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Use Existing Assets
                  </Typography>
                  <CustomTooltip text="Use Existing Assets tooltip" />
                </Stack>
                <Controller
                  name="use_existing_assets"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      labelPlacement="start"
                      control={<CustomSwitch {...field} />}
                      style={{ paddingRight: "10px" }}
                    />
                  )}
                />
              </Stack>
              <TextField
                name="assets_amount_to_use"
                placeholder="Assets amount to use"
                type="text"
                size="small"
                disabled={!useExistingAssets}
                onKeyDown={(e) => numbersOnly(e, "float", false)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">USDC</InputAdornment>
                  ),
                  style: { fontSize: 15 },
                }}
                style={{
                  width: "100%",
                  cursor: useExistingAssets ? "default" : "not-allowed",
                }}
                {...register("assets_amount_to_use")}
              />
              {errors.assets_amount_to_use?.message && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: 14,
                    fontWeight: 500,
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {errors.assets_amount_to_use?.message}
                </span>
              )}
            </Stack>
          </CustomCard>
          {/* End of UnitsSection */}

          {/* BuyPriceSection */}
          {!useExistingAssets && (
            <CustomCard cardTitle="Buy Price">
              <Stack gap={"20px"}>
                <Stack gap={"10px"}>
                  <CustomTab
                    options={orderTypeOptions}
                    selectedOrderType={orderType}
                    onSelect={handleOrderTypeChange}
                  />
                  <span
                    style={{
                      color: "#5d6e77",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {orderTypeMessages[orderType] || "Invalid order type"}
                  </span>
                </Stack>
                <Stack>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600", paddingBottom: "5px" }}
                  >
                    Order Quantity
                  </Typography>
                  <TextField
                    name="entry_order_quantity"
                    placeholder="Order Quantity"
                    type="text"
                    size="small"
                    onKeyDown={(e) => numbersOnly(e, "float", false)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {selectedBaseSymbol}
                        </InputAdornment>
                      ),
                      style: { fontSize: 15 },
                    }}
                    style={{ width: "100%" }}
                    {...register("entry_order_quantity")}
                  />
                  {errors.entry_order_quantity?.message && (
                    <span
                      style={{
                        color: "#d32f2f",
                        fontSize: 14,
                        fontWeight: 500,
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      {errors.entry_order_quantity?.message}
                    </span>
                  )}
                </Stack>
                {orderType === "cond." && (
                  <Stack gap={"10px"}>
                    <CustomTab
                      options={conditionOrderTypeOptions}
                      selectedOrderType={conditionOrderType}
                      onSelect={handleConditionalOrderTypeChange}
                    />
                    <span
                      style={{
                        color: "#5d6e77",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {conditionOrderType === "cond. limit"
                        ? "Will be placed on the exchange order book when the conditional order triggers"
                        : "Will be executed at market rates when the conditional order triggers"}
                    </span>
                  </Stack>
                )}
                {(orderType === "limit" ||
                  (orderType === "cond." &&
                    conditionOrderType === "cond. limit")) && (
                  <Stack>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      style={{ fontWeight: "600", paddingBottom: "5px" }}
                    >
                      Order Price
                    </Typography>
                    <TextField
                      name="entry_order_price"
                      placeholder="Order Price"
                      type="text"
                      size="small"
                      onKeyDown={(e) => numbersOnly(e, "float", false)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {selectedQuoteSymbol}
                          </InputAdornment>
                        ),
                        style: { fontSize: 15 },
                      }}
                      style={{ width: "100%" }}
                      {...register("entry_order_price")}
                    />
                    {errors.entry_order_price?.message && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: 14,
                          fontWeight: 500,
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        {errors.entry_order_price?.message}
                      </span>
                    )}
                  </Stack>
                )}
                {orderType === "cond." && (
                  <Stack>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      style={{ fontWeight: "600", paddingBottom: "5px" }}
                    >
                      Order Trigger Price
                    </Typography>
                    <TextField
                      name="entry_order_trigger_price"
                      placeholder="Order Trigger Price"
                      type="text"
                      size="small"
                      onKeyDown={(e) => numbersOnly(e, "float", false)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {selectedQuoteSymbol}
                          </InputAdornment>
                        ),
                        style: { fontSize: 15 },
                      }}
                      style={{ width: "100%" }}
                      {...register("entry_order_trigger_price")}
                    />
                    {errors.entry_order_trigger_price?.message && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: 14,
                          fontWeight: 500,
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        {errors.entry_order_trigger_price?.message}
                      </span>
                    )}
                  </Stack>
                )}
                {orderType !== "market" && (
                  <Stack style={{ width: "fit-content" }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      paddingBottom={"5px"}
                    >
                      <Stack direction="row" gap={"5px"}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ fontWeight: "600" }}
                        >
                          Trailing buy
                        </Typography>
                        <CustomTooltip text="Trailing buy tooltip" />
                      </Stack>
                      <Controller
                        name="is_entry_trailing_buy"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <FormControlLabel
                            labelPlacement="start"
                            control={<CustomSwitch {...field} />}
                            style={{ paddingRight: "10px" }}
                          />
                        )}
                      />
                    </Stack>

                    <Stack>
                      <TextField
                        name="trailing_buy_percentage"
                        type="text"
                        placeholder="Trailing buy"
                        variant="outlined"
                        size="small"
                        disabled={!isEntryTrailingBuy}
                        onKeyDown={(e) => numbersOnly(e, "float", false)}
                        style={{
                          cursor: isEntryTrailingBuy
                            ? "default"
                            : "not-allowed",
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ gap: "10px" }}
                            >
                              <span>%</span>
                              <AddIcon
                                onClick={() => {
                                  clearErrors("trailing_buy_percentage");
                                  if (!isEntryTrailingBuy) {
                                    return;
                                  } else if (
                                    parseFloat(
                                      getValues("trailing_buy_percentage") || 0
                                    ) +
                                      1 <=
                                    1
                                  ) {
                                    setValue("trailing_buy_percentage", "1");
                                  } else {
                                    setValue(
                                      "trailing_buy_percentage",
                                      parseFloat(
                                        getValues("trailing_buy_percentage")
                                      ) +
                                        1 +
                                        ""
                                    );
                                  }
                                }}
                                width={"20px"}
                                height={"20px"}
                                style={{
                                  cursor: isEntryTrailingBuy
                                    ? "pointer"
                                    : "not-allowed",
                                }}
                              />
                              <RemoveIcon
                                onClick={() => {
                                  clearErrors("trailing_buy_percentage");
                                  if (
                                    !isEntryTrailingBuy ||
                                    isNaN(
                                      parseFloat(
                                        getValues("trailing_buy_percentage")
                                      )
                                    ) ||
                                    parseFloat(
                                      getValues("trailing_buy_percentage")
                                    ) -
                                      1 <
                                      1
                                  ) {
                                    return;
                                  } else {
                                    setValue(
                                      "trailing_buy_percentage",
                                      parseFloat(
                                        getValues("trailing_buy_percentage")
                                      ) -
                                        1 +
                                        ""
                                    );
                                  }
                                }}
                                width={"20px"}
                                height={"20px"}
                                style={{
                                  cursor:
                                    isEntryTrailingBuy &&
                                    parseFloat(
                                      getValues("trailing_buy_percentage") || 0
                                    ) > 1
                                      ? "pointer"
                                      : "not-allowed",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        {...register("trailing_buy_percentage")}
                      />
                      {errors.trailing_buy_percentage?.message && (
                        <span
                          style={{
                            color: "#d32f2f",
                            fontSize: 14,
                            fontWeight: 500,
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          {errors.trailing_buy_percentage?.message}
                        </span>
                      )}
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </CustomCard>
          )}
          {/* End of BuyPriceSection */}

          {/* TotalSection */}
          <CustomCard cardTitle="Total">
            <TextField
              placeholder="Total Amount"
              size="small"
              value={totalAmount ? totalAmount : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {selectedQuoteSymbol}
                  </InputAdornment>
                ),
                style: { fontSize: 15 },
              }}
              style={{ width: "100%" }}
              disabled
            />
          </CustomCard>
          {/* End of TotalSection */}
        </Box>

        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              <CustomCard
                cardTitle="Take Profit"
                rightComponent={
                  <Controller
                    name="is_take_profit"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <FormControlLabel
                        labelPlacement="start"
                        control={<CustomSwitch {...field} />}
                        style={{ paddingRight: "10px" }}
                      />
                    )}
                  />
                }
              >
                <Stack direction="column" gap={"5px"}>
                  <Stack gap={"10px"}>
                    <CustomTab
                      isDisabled={!isTakeProfit}
                      options={takeProfitOrderTypeOptions}
                      selectedOrderType={takeProfitOrderType}
                      onSelect={handleTakeProfitOrderTypeChange}
                    />
                    <span
                      style={{
                        color: "#5d6e77",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      The order will be placed on the exchange order book
                      beforehand
                    </span>
                  </Stack>
                  <Stack paddingBlock={"20px"} gap={"5px"}>
                    {fields.length > 0 && (
                      <Stack gap={"5px"}>
                        <Stack
                          paddingBottom={"10px"}
                          direction={"row"}
                          gap={"5px"}
                        >
                          <Typography
                            variant="subtitle1"
                            style={{
                              flex: 1,
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            Price
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{
                              flex: 1,
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            Quantity (%)
                          </Typography>
                          <span style={{ width: "40px" }}></span>
                        </Stack>
                        {fields.map((row, index) => (
                          <Stack
                            key={row.id}
                            direction={"row"}
                            gap={"5px"}
                            height={"50px"}
                          >
                            <Stack
                              direction={"column"}
                              textAlign={"center"}
                              flex={1}
                            >
                              <TextField
                                type="text"
                                size="small"
                                variant="standard"
                                placeholder="Price"
                                onKeyDown={(e) =>
                                  numbersOnly(e, "float", false)
                                }
                                disabled={!isTakeProfit}
                                inputProps={{
                                  style: { textAlign: "center" },
                                }}
                                style={{ width: "100%" }}
                                {...register(`take_profit.${index}.price`)}
                              />
                              {errors.take_profit &&
                                errors.take_profit[index]?.price?.message && (
                                  <span
                                    style={{
                                      color: "#d32f2f",
                                      fontSize: 14,
                                      fontWeight: 500,
                                      marginTop: "5px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {errors.take_profit[index]?.price?.message}
                                  </span>
                                )}
                            </Stack>
                            <Stack
                              direction={"column"}
                              textAlign={"center"}
                              flex={1}
                            >
                              <TextField
                                type="text"
                                size="small"
                                variant="standard"
                                placeholder="Quantity"
                                disabled={!isTakeProfit}
                                onKeyDown={(e) => {
                                  clearErrors("take_profit_add");
                                  return numbersOnly(e, "float", false);
                                }}
                                inputProps={{
                                  style: { textAlign: "center" },
                                }}
                                style={{ width: "100%" }}
                                {...register(`take_profit.${index}.quantity`)}
                              />
                              {errors.take_profit &&
                                errors.take_profit[index]?.quantity
                                  ?.message && (
                                  <span
                                    style={{
                                      color: "#d32f2f",
                                      fontSize: 14,
                                      fontWeight: 500,
                                      marginTop: "5px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {
                                      errors.take_profit[index]?.quantity
                                        ?.message
                                    }
                                  </span>
                                )}
                            </Stack>
                            <IconButton
                              aria-label="delete"
                              disabled={!isTakeProfit}
                              onClick={() => {
                                remove(index);
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Stack>
                        ))}
                      </Stack>
                    )}
                    {fields.length < 5 ? (
                      <Stack style={{ textAlign: "center" }}>
                        <Button
                          name="take_profit_add"
                          sx={{
                            width: "fit-content",
                            marginInline: "auto",
                            fontWeight: 600,
                            textTransform: "capitalize",
                          }}
                          disabled={!isTakeProfit}
                          variant="contained"
                          color="primary"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            let totalTPQuantity = 0.0;
                            takeProfits.forEach((field) => {
                              totalTPQuantity += parseFloat(field.quantity);
                            });
                            const remainingQuantity = 100 - totalTPQuantity;
                            append({
                              price: "",
                              quantity: remainingQuantity,
                            });
                          }}
                        >
                          Add
                        </Button>
                        {errors["take_profit_add"]?.message && (
                          <span
                            style={{
                              color: "#d32f2f",
                              fontSize: 14,
                              fontWeight: 500,
                              marginTop: "5px",
                              marginLeft: "5px",
                            }}
                          >
                            {errors["take_profit_add"]?.message}
                          </span>
                        )}
                      </Stack>
                    ) : null}
                    <Stack textAlign={"center"}>
                      <Typography>{fields.length} TP targets set</Typography>
                    </Stack>
                    {fields.length > 0 && (
                      <Stack paddingTop={"20px"}>
                        <Button
                          variant="contained"
                          color="error"
                          startIcon={<CloseIcon />}
                          disabled={!isTakeProfit}
                          sx={{
                            fontWeight: 600,
                            textTransform: "capitalize",
                          }}
                          onClick={() => {
                            remove();
                          }}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                  {takeProfitOrderType === "limit" && (
                    <>
                      <Stack direction="row" alignItems="center">
                        <Stack direction="row" gap={"5px"}>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            style={{ fontWeight: "600" }}
                          >
                            Trailing Take Profit for last target
                          </Typography>
                          <CustomTooltip text="Trailing Take Profit for last target tooltip" />
                        </Stack>
                        <Controller
                          name="is_trailing_take_profit_for_last_target"
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              disabled={!isTakeProfit}
                              labelPlacement="start"
                              control={<CustomSwitch {...field} />}
                              style={{
                                paddingRight: "10px",
                                cursor: isTakeProfit
                                  ? "default"
                                  : "not-allowed",
                              }}
                            />
                          )}
                        />
                      </Stack>
                      <Stack>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ fontWeight: "600" }}
                        >
                          Follow max price with deviation (%)
                        </Typography>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          gap={"20px"}
                        >
                          <Controller
                            name="take_profit_max_price_with_deviation_percentage"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <>
                                <Slider
                                  value={value === "" ? 0 : parseFloat(value)}
                                  onChange={(e, val) => onChange(val)}
                                  disabled={
                                    !isTakeProfit
                                      ? true
                                      : !isTrailingTakeProfitForLastTarget
                                      ? true
                                      : false
                                  }
                                  min={-100.0}
                                  max={0}
                                  step={0.01}
                                  aria-labelledby="deviation-slider"
                                  valueLabelDisplay="auto"
                                  style={{
                                    marginLeft: "10px",
                                    cursor: isTrailingTakeProfitForLastTarget
                                      ? "default"
                                      : "not-allowed",
                                  }}
                                />
                                <TextField
                                  type="number"
                                  value={value}
                                  onChange={(e) => onChange(e.target.value)}
                                  disabled={
                                    !isTakeProfit
                                      ? true
                                      : !isTrailingTakeProfitForLastTarget
                                      ? true
                                      : false
                                  }
                                  size="small"
                                  onKeyDown={(e) =>
                                    numbersOnly(e, "float", true)
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        %
                                      </InputAdornment>
                                    ),
                                    style: { fontSize: 15 },
                                  }}
                                  style={{
                                    width: "45%",
                                    cursor: isTrailingTakeProfitForLastTarget
                                      ? "default"
                                      : "not-allowed",
                                  }}
                                />
                              </>
                            )}
                          />
                        </Stack>
                        {errors.take_profit_max_price_with_deviation_percentage
                          ?.message && (
                          <span
                            style={{
                              color: "#d32f2f",
                              fontSize: 14,
                              fontWeight: 500,
                              marginTop: "5px",
                              marginLeft: "5px",
                            }}
                          >
                            {
                              errors
                                .take_profit_max_price_with_deviation_percentage
                                ?.message
                            }
                          </span>
                        )}
                      </Stack>
                    </>
                  )}
                  {isTakeProfit && (
                    <Stack direction="column" gap={"5px"}>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ fontWeight: "600" }}
                      >
                        Approximate Profit:
                      </Typography>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={"8px"}
                      >
                        <img
                          width="20px"
                          height="20px"
                          src="usdc.svg"
                          alt="usdc"
                        />
                        {takeProfitAmount &&
                        takeProfitAmountUSD &&
                        takeProfitPercentage ? (
                          <>
                            <span
                              style={{ fontWeight: "600", color: "#00ae9a" }}
                            >
                              {takeProfitAmount}
                            </span>
                            <span
                              style={{ fontWeight: "600", color: "#00ae9a" }}
                            >
                              {takeProfitAmountUSD}
                            </span>
                            <span
                              style={{ fontWeight: "600", color: "#00ae9a" }}
                            >
                              {takeProfitPercentage}
                            </span>
                          </>
                        ) : (
                          <span style={{ fontWeight: "600", color: "#00ae9a" }}>
                            ... ... ...
                          </span>
                        )}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </CustomCard>
            </Box>
            <Box
              sx={{
                flex: 1,
              }}
            >
              <CustomCard
                cardTitle="Stop Loss"
                rightComponent={
                  <Controller
                    name="is_stop_loss"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <FormControlLabel
                        labelPlacement="start"
                        control={<CustomSwitch {...field} />}
                        style={{ paddingRight: "10px" }}
                      />
                    )}
                  />
                }
              >
                <Stack direction="column" gap={"20px"}>
                  <Stack gap={"10px"}>
                    <CustomTab
                      isDisabled={!isStopLoss}
                      options={stopLossOrderTypeOptions}
                      selectedOrderType={stopLossOrderType}
                      onSelect={handleStopLossOrderTypeChange}
                    />
                    <span
                      style={{
                        color: "#5d6e77",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      The order will be placed on the exchange when the price
                      meets Stop Loss conditions
                    </span>
                  </Stack>
                  <Stack>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      style={{ fontWeight: "600" }}
                    >
                      Price
                    </Typography>
                    <Stack direction={"row"} alignItems="center" gap={"5px"}>
                      <Controller
                        name="stop_loss_price_type"
                        control={control}
                        disabled={!isStopLoss}
                        render={({ field }) => (
                          <Select
                            {...field}
                            size="small"
                            variant="outlined"
                            style={{ flex: 1 }}
                          >
                            {stopLossPriceOptions.map((option) => (
                              <MenuItem
                                key={option}
                                value={option.toLowerCase()}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <Stack style={{ flex: 4 }}>
                        <Stack>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            style={{ fontWeight: "600" }}
                          ></Typography>
                          <Stack
                            direction={"row"}
                            alignItems="center"
                            gap={"20px"}
                          >
                            <TextField
                              type="text"
                              size="small"
                              variant="outlined"
                              placeholder="Stop Price"
                              disabled={!isStopLoss}
                              onKeyDown={(e) => numbersOnly(e, "float", false)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {selectedQuoteSymbol}
                                  </InputAdornment>
                                ),
                                style: { fontSize: 15 },
                              }}
                              style={{ flex: 3 }}
                              {...register("stop_loss_price")}
                            />
                            {stopLossPercentage && (
                              <span
                                style={{ color: "#1976d2", fontWeight: "600" }}
                              >
                                {stopLossPercentage}
                              </span>
                            )}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    {errors.stop_loss_price?.message && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: 14,
                          fontWeight: 500,
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        {errors.stop_loss_price?.message}
                      </span>
                    )}
                  </Stack>
                  {stopLossOrderType === "limit" && (
                    <Stack>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ fontWeight: "600" }}
                      >
                        Order Price
                      </Typography>
                      <Stack direction={"row"} alignItems="center" gap={"20px"}>
                        <TextField
                          type="text"
                          size="small"
                          variant="outlined"
                          placeholder="Order Price"
                          disabled={!isStopLoss}
                          onKeyDown={(e) => numbersOnly(e, "float", false)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {selectedQuoteSymbol}
                              </InputAdornment>
                            ),
                            style: { fontSize: 15 },
                          }}
                          style={{ flex: 3 }}
                          {...register("stop_loss_limit_price")}
                        />
                        {stopLossLimitPercentage && (
                          <span style={{ color: "#1976d2", fontWeight: "600" }}>
                            {stopLossLimitPercentage}
                          </span>
                        )}
                      </Stack>
                      {errors.stop_loss_limit_price?.message && (
                        <span
                          style={{
                            color: "#d32f2f",
                            fontSize: 14,
                            fontWeight: 500,
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          {errors.stop_loss_limit_price?.message}
                        </span>
                      )}
                    </Stack>
                  )}
                  <Stack style={{ width: "fit-content" }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      paddingBottom={"5px"}
                    >
                      <Stack direction="row" gap={"5px"}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ fontWeight: "600" }}
                        >
                          Stop Loss timeout
                        </Typography>
                        <CustomTooltip text="Stop Loss timeout tooltip" />
                      </Stack>
                      <Controller
                        name="is_stop_loss_timeout"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            disabled={!isStopLoss}
                            labelPlacement="start"
                            control={<CustomSwitch {...field} />}
                            style={{
                              paddingRight: "10px",
                              cursor: isStopLoss ? "default" : "not-allowed",
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack>
                      <TextField
                        name="stop_loss_timeout"
                        type="number"
                        placeholder="Stop Loss timeout"
                        variant="outlined"
                        size="small"
                        disabled={
                          !isStopLoss ? true : !isStopLossTimeout ? true : false
                        }
                        onKeyDown={(e) => numbersOnly(e, "int", false)}
                        style={{
                          cursor: isStopLossTimeout ? "default" : "not-allowed",
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ gap: "10px" }}
                            >
                              <span>Sec</span>
                              <AddIcon
                                onClick={() => {
                                  clearErrors("stop_loss_timeout");
                                  if (!isStopLossTimeout) {
                                    return;
                                  } else if (
                                    parseInt(
                                      getValues("stop_loss_timeout") || 0
                                    ) +
                                      1 <=
                                    1
                                  ) {
                                    setValue("stop_loss_timeout", "1");
                                  } else {
                                    setValue(
                                      "stop_loss_timeout",
                                      parseInt(getValues("stop_loss_timeout")) +
                                        1 +
                                        ""
                                    );
                                  }
                                }}
                                width={"20px"}
                                height={"20px"}
                                style={{
                                  cursor: isStopLossTimeout
                                    ? "pointer"
                                    : "not-allowed",
                                }}
                              />
                              <RemoveIcon
                                onClick={() => {
                                  clearErrors("stop_loss_timeout");
                                  if (
                                    !isStopLossTimeout ||
                                    isNaN(
                                      parseInt(getValues("stop_loss_timeout"))
                                    ) ||
                                    parseInt(getValues("stop_loss_timeout")) -
                                      1 <
                                      1
                                  ) {
                                    return;
                                  } else {
                                    setValue(
                                      "stop_loss_timeout",
                                      parseInt(getValues("stop_loss_timeout")) -
                                        1 +
                                        ""
                                    );
                                  }
                                }}
                                width={"20px"}
                                height={"20px"}
                                style={{
                                  cursor:
                                    isStopLossTimeout &&
                                    parseInt(
                                      getValues("stop_loss_timeout") || 0
                                    ) > 1
                                      ? "pointer"
                                      : "not-allowed",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        {...register("stop_loss_timeout")}
                      />
                      {errors.stop_loss_timeout?.message && (
                        <span
                          style={{
                            color: "#d32f2f",
                            fontSize: 14,
                            fontWeight: 500,
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          {errors.stop_loss_timeout?.message}
                        </span>
                      )}
                    </Stack>
                  </Stack>

                  <Stack style={{ width: "fit-content" }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      paddingBottom={"5px"}
                    >
                      <Stack direction="row" gap={"5px"}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ fontWeight: "600" }}
                        >
                          Trailing Stop Loss
                        </Typography>
                        <CustomTooltip text="Trailing Stop Loss tooltip" />
                      </Stack>
                      <Controller
                        name="is_trailing_stop_loss"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            disabled={!isStopLoss}
                            labelPlacement="start"
                            control={<CustomSwitch {...field} />}
                            style={{
                              paddingRight: "10px",
                              cursor: isStopLoss ? "default" : "not-allowed",
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack>
                      <TextField
                        name="trailing_stop_loss_percentage"
                        type="text"
                        placeholder="Trailing Stop Loss"
                        variant="outlined"
                        size="small"
                        disabled={
                          !isStopLoss
                            ? true
                            : !isTrailingStopLoss
                            ? true
                            : false
                        }
                        onKeyDown={(e) => numbersOnly(e, "float", false)}
                        style={{
                          cursor: isTrailingStopLoss
                            ? "default"
                            : "not-allowed",
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ gap: "10px" }}
                            >
                              %
                            </InputAdornment>
                          ),
                        }}
                        {...register("trailing_stop_loss_percentage")}
                      />
                      {errors.trailing_stop_loss_percentage?.message && (
                        <span
                          style={{
                            color: "#d32f2f",
                            fontSize: 14,
                            fontWeight: 500,
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          {errors.trailing_stop_loss_percentage?.message}
                        </span>
                      )}
                    </Stack>
                  </Stack>
                  <Stack style={{ width: "fit-content" }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      paddingBottom={"5px"}
                    >
                      <Stack direction="row" gap={"5px"}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ fontWeight: "600" }}
                        >
                          Move to Breakeven
                        </Typography>
                        <CustomTooltip text="Move to Breakeven tooltip" />
                      </Stack>
                      <Controller
                        name="is_move_to_breakeven"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            disabled={!isStopLoss}
                            labelPlacement="start"
                            control={<CustomSwitch {...field} />}
                            style={{
                              paddingRight: "10px",
                              cursor: isStopLoss ? "default" : "not-allowed",
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack>
                      <TextField
                        name="move_to_breakeven_points"
                        type="text"
                        placeholder="Move to Breakeven"
                        variant="outlined"
                        size="small"
                        disabled={
                          !isStopLoss ? true : !isMoveToBreakeven ? true : false
                        }
                        onKeyDown={(e) => numbersOnly(e, "float", false)}
                        style={{
                          cursor: isMoveToBreakeven ? "default" : "not-allowed",
                        }}
                        {...register("move_to_breakeven_points")}
                      />
                      {errors.move_to_breakeven_points?.message && (
                        <span
                          style={{
                            color: "#d32f2f",
                            fontSize: 14,
                            fontWeight: 500,
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          {errors.move_to_breakeven_points?.message}
                        </span>
                      )}
                    </Stack>
                  </Stack>
                  {isStopLoss && (
                    <>
                      <Stack direction="column" gap={"5px"}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ fontWeight: "600" }}
                        >
                          Approximate Loss:
                        </Typography>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={"8px"}
                        >
                          <img
                            width="20px"
                            height="20px"
                            src="usdc.svg"
                            alt="usdc"
                          />
                          {stopLossAmount &&
                          stopLossAmountUSD &&
                          (stopLossOrderType === "limit"
                            ? stopLossLimitPercentage
                            : stopLossPercentage) ? (
                            <>
                              <span
                                style={{ fontWeight: "600", color: "#e67387" }}
                              >
                                {stopLossAmount}
                              </span>
                              <span
                                style={{ fontWeight: "600", color: "#e67387" }}
                              >
                                {stopLossAmountUSD}
                              </span>
                              <span
                                style={{ fontWeight: "600", color: "#e67387" }}
                              >
                                {stopLossOrderType === "limit"
                                  ? stopLossLimitPercentage
                                  : stopLossPercentage}
                              </span>
                            </>
                          ) : (
                            <span
                              style={{ fontWeight: "600", color: "#e67387" }}
                            >
                              ... ... ...
                            </span>
                          )}
                        </Stack>
                      </Stack>
                      <Stack direction="row" alignItems="center" gap={"5px"}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ fontWeight: "600" }}
                        >
                          Risk/Reward Ratio:
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ fontWeight: "600" }}
                        >
                          {stopLossAmount && takeProfitAmount ? (
                            <Stack direction={"row"} gap={0.6}>
                              <span>1</span>
                              <span>:</span>
                              <span>
                                {(
                                  parseFloat(takeProfitAmount) /
                                  (parseFloat(stopLossAmount) < 0
                                    ? parseFloat(stopLossAmount) * -1
                                    : parseFloat(stopLossAmount))
                                )
                                  .toFixed(5)
                                  .toString()
                                  .replace(/\.?0*$/, "")}
                              </span>
                            </Stack>
                          ) : (
                            <span>... : ...</span>
                          )}
                        </Typography>
                      </Stack>
                    </>
                  )}
                </Stack>
              </CustomCard>
            </Box>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formSubmitted}
            style={{
              fontWeight: 600,
              cursor: formSubmitted ? "not-allowed" : "default",
            }}
          >
            Place Order
          </Button>
          <Button
            type="button"
            variant="contained"
            color="error"
            style={{
              fontWeight: 600,
              marginInline: "auto",
              width: "fit-content",
            }}
            onClick={(e) => {
              logout();
            }}
          >
            Log out
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default EntryOrderSection;
