import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Stack, TextField, Typography } from "@mui/material";

import { CustomToast } from "../components/CustomToast";
import CustomCard from "../components/CustomCard";

import { forget_password } from "../services/password.service";

const ForgetPasswordPage = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const forgetPasswordValues = {
    email: null,
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...forgetPasswordValues,
    },
  });

  const onSubmit = (data) => {
    setFormSubmitted(true);

    if (!data["email"]) {
      setError(
        "email",
        {
          type: "required",
          message: "Email ID is required",
        },
        { shouldFocus: true }
      );
      setFormSubmitted(false);
      return;
    }

    forget_password(data)
      .then((response) => {
        if (response.data.success) {
          CustomToast({
            message: response.data.msg,
            type: "success",
          });
          setFormSubmitted(false);
        } else {
          CustomToast({
            message: response.data.msg,
            type: "error",
          });
        }
        setFormSubmitted(false);
      })
      .catch((err) => {
        CustomToast({
          message: err.response.data.msg || err,
          type: "error",
        });
        setFormSubmitted(false);
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "50px",
      }}
    >
      <Stack minWidth="25vw">
        <CustomCard cardTitle="Forget Password" rightComponent={<></>}>
          <Stack gap={"25px"}>
            <Stack direction="column" gap={"5px"}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom={"5px"}
              >
                <Stack direction="row" gap={"5px"}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "600" }}
                  >
                    Email ID
                  </Typography>
                </Stack>
              </Stack>
              <TextField
                name="email"
                placeholder="Email ID"
                type="email"
                size="small"
                {...register("email")}
              />
              {errors.email?.message && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: 14,
                    fontWeight: 500,
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                >
                  {errors.email?.message}
                </span>
              )}
            </Stack>
            <Stack direction="column" gap={"5px"}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formSubmitted}
                style={{
                  fontWeight: 600,
                  cursor: formSubmitted ? "not-allowed" : "default",
                }}
              >
                Send Reset Link
              </Button>
            </Stack>
          </Stack>
        </CustomCard>
      </Stack>
    </form>
  );
};

export default ForgetPasswordPage;
