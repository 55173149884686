import { Tooltip } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';

const CustomTooltip = ({ text }) => {
  return (
    <Tooltip title={text} style={{ cursor: "pointer" }}>
      <HelpIcon style={{ color: "#939da2", cursor: "pointer"}}/>
    </Tooltip>
  );
};

export default CustomTooltip;
