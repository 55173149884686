import { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import CustomCard from "../components/CustomCard";
import CustomLoader from "../components/CustomLoader";
import { CustomToast } from "../components/CustomToast";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { auth_signup, delete_user, get_users } from "../services/auth.service";

import { useAuth } from "../auth/AuthContext";
import { isStrongPassword } from "../validator/passwordValidator";

const AdminPage = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(true);

  const [selectedUserID, setSelectedUserID] = useState(null);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleDeleteUser = (userId) => {
    delete_user(userId)
      .then((response) => {
        if (response.data.success) {
          CustomToast({
            message: response.data.msg,
            type: "success",
          });
          setReloadUsers(true);
          setOpenDeleteUserModal(false);
        } else {
          CustomToast({
            message: response.data.msg,
            type: "error",
          });
        }
      })
      .catch(({ response }) => {
        if (response.status === 401 || response.status === 403) {
          CustomToast({
            message: response.data.detail,
            type: "error",
          });
          logout();
        } else {
          CustomToast({
            message: response.data,
            type: "error",
          });
        }
      });
  };

  const usersDataValues = {
    username: null,
    email: null,
    password: null,
    confirm_password: null,
  };

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...usersDataValues,
    },
  });

  const onSubmit = (data) => {
    setFormSubmitted(true);

    const requiredFields = [
      { name: "username", message: "Username must be provided" },
      { name: "email", message: "Email ID must be provided" },
      { name: "password", message: "Password must be provided" },
      {
        name: "confirm_password",
        message: "Confirm Password must be provided",
      },
    ];

    for (const field of requiredFields) {
      if (!data[field.name]) {
        setError(
          field.name,
          { type: "required", message: field.message },
          { shouldFocus: true }
        );
        setFormSubmitted(false);
        return;
      }
    }

    if (data["password"] !== data["confirm_password"]) {
      setError(
        "confirm_password",
        {
          type: "required",
          message: "Confirm Password should be same as Password",
        },
        { shouldFocus: true }
      );
      setFormSubmitted(false);
      return;
    } else {
      delete data["confirm_password"];
    }

    if (!isStrongPassword(data["password"])) {
      setError(
        "password",
        {
          type: "validate",
          message:
            "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character",
        },
        { shouldFocus: true }
      );
      setFormSubmitted(false);
      return;
    }

    auth_signup(data)
      .then((response) => {
        if (response.data.success) {
          CustomToast({
            message: response.data.msg,
            type: "success",
          });
          reset();
          setFormSubmitted(false);
          setOpenAddUserModal(false);
          setReloadUsers(true);
        } else {
          CustomToast({
            message: response.data.msg,
            type: "error",
          });
        }
        setFormSubmitted(false);
      })
      .catch(({ response }) => {
        if (response.status === 401 || response.status === 403) {
          CustomToast({
            message: response.data.detail,
            type: "error",
          });
          logout();
        } else {
          CustomToast({
            message: response.data.msg || response.data,
            type: "error",
          });
        }
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (!reloadUsers) {
      return;
    }
    setIsUsersLoading(true);
    get_users()
      .then((response) => {
        if (response.data.success) {
          setUsers(response.data.users);
        } else {
          CustomToast({
            message: response.data.msg,
            type: "error",
          });
        }
        setIsUsersLoading(false);
        setReloadUsers(false);
      })
      .catch(({ response }) => {
        if (response.status === 401 || response.status === 403) {
          CustomToast({
            message: response.data.detail,
            type: "error",
          });
          logout();
        } else {
          CustomToast({
            message: response.data,
            type: "error",
          });
        }
        setIsUsersLoading(false);
        setReloadUsers(false);
      });
    // eslint-disable-next-line
  }, [reloadUsers]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "20px",
        gap: "20px",
      }}
    >
      <CustomCard
        cardTitle="Users Table"
        rightComponent={
          <Stack
            direction="row"
            columnGap="20px"
            style={{ marginRight: "40px" }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontWeight: 600,
              }}
              onClick={() => {
                setOpenAddUserModal(true);
              }}
            >
              Add New User
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{
                fontWeight: 600,
              }}
              onClick={() => {
                navigate("/home");
              }}
            >
              Go to home
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                fontWeight: 600,
              }}
              onClick={() => {
                logout();
              }}
            >
              Log out
            </Button>
          </Stack>
        }
      >
        {/* Beginning of Users Table  */}
        <TableContainer style={{ maxHeight: "60vh" }}>
          <Table>
            <TableHead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "#fff",
              }}
            >
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>ID</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Username</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Account Verified</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflowY: "auto" }}>
              {isUsersLoading ? (
                <TableRow>
                  <TableCell style={{ textAlign: "center" }} colSpan={7}>
                    <CustomLoader />
                  </TableCell>
                </TableRow>
              ) : users.length > 0 ? (
                users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.is_active ? (
                        <CheckCircleIcon fontSize="large" color="success" />
                      ) : (
                        <CancelIcon fontSize="large" color="error" />
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="error"
                        sx={{
                          fontWeight: 600,
                        }}
                        onClick={() => {
                          setSelectedUserID(user.id);
                          setOpenDeleteUserModal(true);
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    style={{ textAlign: "center", letterSpacing: "1.5px" }}
                    colSpan={7}
                  >
                    No Users found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* End of Users Table */}

        {/* Beginning of Delete Confirmation Modal */}
        {openDeleteUserModal && (
          <Modal
            open={openDeleteUserModal}
            onClose={() => {
              setSelectedUserID(null);
              setOpenDeleteUserModal(false);
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "background.paper",
                boxShadow: 24,
                width: "25vw",
              }}
            >
              <CustomCard cardTitle="Delete User">
                <Stack gap={"25px"}>
                  <Stack>
                    <Typography>
                      Are you sure you want to delete this user?
                    </Typography>
                  </Stack>
                  <Stack direction="row" columnGap="10px" justifyContent="end">
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        handleDeleteUser(selectedUserID);
                      }}
                    >
                      Confirm
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        setSelectedUserID(null);
                        setOpenDeleteUserModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </CustomCard>
            </div>
          </Modal>
        )}
        {/* Ending of Delete Confirmation Modal */}

        {/* Beginning of Take Profit Modal */}
        {openAddUserModal && (
          <Modal
            open={openAddUserModal}
            onClose={() => {
              reset();
              setOpenAddUserModal(false);
            }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "background.paper",
                boxShadow: 24,
                width: "25vw",
              }}
            >
              <CustomCard cardTitle="Sign Up" rightComponent={<></>}>
                <Stack gap={"25px"}>
                  <Stack direction="column" gap={"5px"}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingBottom={"5px"}
                    >
                      <Stack direction="row" gap={"5px"}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ fontWeight: "600" }}
                        >
                          Username
                        </Typography>
                      </Stack>
                    </Stack>
                    <TextField
                      name="username"
                      placeholder="Username"
                      type="text"
                      size="small"
                      {...register("username")}
                    />
                    {errors.username?.message && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: 14,
                          fontWeight: 500,
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        {errors.username?.message}
                      </span>
                    )}
                  </Stack>
                  <Stack direction="column" gap={"5px"}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingBottom={"5px"}
                    >
                      <Stack direction="row" gap={"5px"}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ fontWeight: "600" }}
                        >
                          Email ID
                        </Typography>
                      </Stack>
                    </Stack>
                    <TextField
                      name="email"
                      placeholder="Email ID"
                      type="email"
                      size="small"
                      {...register("email")}
                    />
                    {errors.email?.message && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: 14,
                          fontWeight: 500,
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        {errors.email?.message}
                      </span>
                    )}
                  </Stack>
                  <Stack direction="column" gap={"5px"}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingBottom={"5px"}
                    >
                      <Stack direction="row" gap={"5px"}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ fontWeight: "600" }}
                        >
                          Password
                        </Typography>
                      </Stack>
                    </Stack>
                    <TextField
                      name="password"
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...register("password")}
                    />
                    {errors.password?.message && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: 14,
                          fontWeight: 500,
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        {errors.password?.message}
                      </span>
                    )}
                  </Stack>
                  <Stack direction="column" gap={"5px"}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingBottom={"5px"}
                    >
                      <Stack direction="row" gap={"5px"}>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          style={{ fontWeight: "600" }}
                        >
                          Confirm Password
                        </Typography>
                      </Stack>
                    </Stack>
                    <TextField
                      name="confirm_password"
                      placeholder="Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...register("confirm_password")}
                    />
                    {errors.confirm_password?.message && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: 14,
                          fontWeight: 500,
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                      >
                        {errors.confirm_password?.message}
                      </span>
                    )}
                  </Stack>
                  <Stack direction="row" gap={"5px"}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={formSubmitted}
                      style={{
                        fontWeight: 600,
                        flex: 1,
                        cursor: formSubmitted ? "not-allowed" : "default",
                      }}
                    >
                      Register
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        fontWeight: 600,
                        flex: 1,
                      }}
                      onClick={() => {
                        reset();
                        setOpenAddUserModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </CustomCard>
            </form>
          </Modal>
        )}
      </CustomCard>
    </Box>
  );
};

export default AdminPage;
