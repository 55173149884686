export const isStrongPassword = (password) => {
  // Minimum length 8
  // At least One uppercase letter
  // At least One lowercase letter
  // At least One digit
  // At least One special character
  const strongPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
  return strongPasswordRegex.test(password);
};
