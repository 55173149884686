import { default as axios } from "axios";
import { ORDERS_API_URL } from "../api.routes";
import { getAuthToken } from "../auth/AuthContext";

export async function editOrder(orderId, updatedData) {
  const res = await axios.put(ORDERS_API_URL + "/" + orderId, updatedData, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
}

export async function cancelOrder(orderId) {
  const res = await axios.delete(ORDERS_API_URL + "/" + orderId, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
}
